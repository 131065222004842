import React, { useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import { useSelector, useDispatch } from "react-redux";
import { fetchHumanLandData } from "../../redux/data/humanData/humanLandDataActions"
import LowBalancePopups from "../Popups/LowBalancePopups";
import LandDetailsPopup from "../Popups/LandDetailsPopup";
import ConfirmationPopup from "../Popups/ConfirmationPopup";
import DirectFarmlandMintingPopup from "../Popups/DirectFarmlandMintingPopup";
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import Tost from "../Tost";
import GetStorageBuildig from "./GetStorageBuildig";

const GetLands = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const landData = useSelector((state) => state.landData);
    const [landDetailsPopop, setLandDetailsPopuo] = useState(false)
    const [directMintPopus, setDirectMintPopup] = useState(false)
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [confirmPupup, setconfirmPupup] = useState(false)
    const [asset, setAsset] = useState('')
    const [selectedLandDetails, setSelectedLandDetails] = useState('')

    async function buyLand(id, price) {
        if (landData.isDirectMint) {
            const gasPrice = await blockchain.web3.eth.getGasPrice()
            const boxPriceFromWei = (blockchain.web3.utils.fromWei(price, 'ether'))
            const gasPriceFromWei = (blockchain.web3.utils.fromWei(gasPrice, 'ether'))
            const totalAmount = (parseFloat(boxPriceFromWei) + parseFloat(gasPriceFromWei))
            const totalAmountToWei = (blockchain.web3.utils.toWei(totalAmount.toString(), 'ether'))
            if (walletBal.walletBalance >= totalAmountToWei) {
                setconfirmPupup(true)
                try {
                    await blockchain.lands.methods.mint(blockchain.account, id)
                        .send({
                            gasPrice: gasPrice,
                            from: blockchain.account,
                            value: price,
                        })
                    Tost("success", "Transaction successful")
                    dispatch(fetchHumanLandData(blockchain.account))
                    setLandDetailsPopuo(false)
                } catch (error) {
                    if (error.code === 4001) {
                        Tost("error", "Denied transaction signature.")
                    } else {
                        Tost("error", error.message)
                    }
                }
                setconfirmPupup(false)
            } else {
                setLoawBalancePopup(true)
            }
        } else {
            setDirectMintPopup(true)
        }


    }

    return (
        <>
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            {landDetailsPopop && <LandDetailsPopup mintFun={buyLand} closeDetailsPopop={setLandDetailsPopuo} ld={selectedLandDetails} />}
            {confirmPupup && <ConfirmationPopup ld={selectedLandDetails} a={asset} />}
            {directMintPopus && <DirectFarmlandMintingPopup closeDirectMintPopup={setDirectMintPopup} a={asset} />}
            <div className=" ">
                <div className=" animate__animated animate__fadeIn grid grid-flow-row-dense grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  p-2 rounded-2xl bg-gray-100  ">

                    {
                        landData.allLands.map((item, key) => {
                            return (
                                <div key={key}>
                                    <div className=" w-auto    flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                                        <div className="relative flex  overflow-hidden">
                                            <img className=""
                                                src={require('../../Assets/Lands/farmland-' + key + '.png')} alt="farmland" />
                                            <div className=" opacity-0 bg-white  hover:opacity-90 duration-500 flex justify-center items-center w-full h-full absolute">
                                                <button
                                                    onClick={() => {

                                                        setSelectedLandDetails(landData.allLands[key])
                                                        setLandDetailsPopuo(true)
                                                    }}
                                                    className=" duration-300 bg-orange-600 font-Inter font-semibold text-white hover:bg-blue-600  rounded-md py-1 px-2">Details</button>
                                            </div>

                                        </div>
                                        <div className=" bg-white  items-center flex space-x-2 justify-center  ">
                                            <p className=" font-Inter font-bold text-gray-500 text-base md:text-sm">Price</p>
                                            <div className=" flex items-center space-x-1  ">
                                                <span className="  text-lg md:text-base font-bold text-purple-600 ">{blockchain.web3.utils.fromWei(item.price, 'ether')} </span>
                                                <img className=" w-3"
                                                    src={polygonIcon} alt="polygon-icon" />
                                            </div>
                                        </div>
                                        <div className=" bg-white ">
                                            {/* <p className=" font-Inter font-bold text-lg text-center text-gray-700"> <span className=" text-sm text-gray-600">{item.landSize} Acres of</span> farmland </p> */}
                                            <p className=" font-Inter font-bold text-xl sm:text-lg md:text-base text-center text-gray-700"> {blockchain.web3.utils.fromWei(item.landSize, 'mwei')} Acres of farmland </p>

                                            <button
                                                onClick={() => {
                                                    setSelectedLandDetails(landData.allLands[key])
                                                    setAsset("land")
                                                    buyLand(item.id, item.price)
                                                }}
                                                className=" duration-500 w-full  py-3 sm:py-3 md:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                                                Mint now
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )

                        })

                    }
                    <GetStorageBuildig />

                </div>
            </div>

        </>
    )

}
export default GetLands