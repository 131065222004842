import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const StorageImageSwiper = () => {
    return (

        <>
            <Swiper

                navigation={true}

                modules={[Navigation]}
                className=" mySwiper mx-1 md:mx-5  py-3"
            >
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Storage_Buildings/barn.png')} alt="barn" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">Barn</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">Agricultural products including hay, grain, and fruits are stored in the barn.</p>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Storage_Buildings/water-tank.png')} alt="water-tank" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">Water Tank</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">To hold onto the water until it is needed for farming and drinking.</p>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Storage_Buildings/silo.png')} alt="silo" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">Silo</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">After cultivating the crops, store grains in large quantities in silos.</p>
                        </div>

                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    )
}
export default StorageImageSwiper;