import React from "react";
import FadeLoader from "react-spinners/FadeLoader";

const Loading = () => {

    return (
        <div className=" flex justify-center mt-20 ">
            <FadeLoader

                color="#c2c2c2"

            />
        </div>
    )
}
export default Loading;