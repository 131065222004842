import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import landDataReducer from "./data/landDataReducer";
import zlBoxDataReducer from "./data/zlBoxDataReducer";
import humanDataReducer from "./data/humanDataReducer";
import humanLandDataReducre from "./data/humanData/humanLandDataReducre";
import humanZlBoxDataReducers from "./data/humanData/humanZlBoxDataReducers";
import humanBalanceReducer from "./data/humanData/humanBalanceReducer";
import humanZamTokenReducer from "./data/humanData/humanZamTokenReducer";
import storageBuildingDataReducers from "./data/storageBuildingDataReducers";
import zsbBoxDataReducer from "./data/zsbBoxDataReducer";
import humanStorageBuildingDataReducers from "./data/humanData/humanStorageBuildingDataReducers";
import presellZamDataReducer from "./data/presellZamDataReducer";

const rootReducer = combineReducers({
    blockchain: blockchainReducer,
    landData: landDataReducer,
    zamiataLandBox: zlBoxDataReducer,
    zamiataStorageBuildingsBox: zsbBoxDataReducer,
    storageBuildingsData: storageBuildingDataReducers,
    human: humanDataReducer,
    humanLand: humanLandDataReducre,
    humanZlBox: humanZlBoxDataReducers,
    humanZamTokenData: humanZamTokenReducer,
    humanStorageBuldings: humanStorageBuildingDataReducers,
    walletBalance: humanBalanceReducer,
    presellZamData: presellZamDataReducer,


});
const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
    return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;