const initialState = {
    loading: true,
    barnData: null,
    barnStorageData: null,
    waterTankData: null,
    waterTankStorage: null,
    siloStorage: null,
    siloData: null,
    //boxController: null,
    error: false,
    errorMsg: "",
};

const storageBuildingDataReducers = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_STORAGE_DATA_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_STORAGE_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                barnData: action.payload.barnData,
                barnStorageData: action.payload.barnStorageData,
                waterTankData: action.payload.waterTankData,
                waterTankStorage: action.payload.waterTankStorage,
                siloData: action.payload.siloData,
                siloStorage: action.payload.siloStorage,
                //boxController: action.payload.boxController,
                //landOwner: action.payload.allOwnerLips,
            };
        case "CHECK_STORAGE_DATA_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default storageBuildingDataReducers;