import React, { useState } from "react";
import 'react-toastify/dist/ReactToastify.css';
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import ConfirmationPopupBox from "../Popups/ConfirmationPopupBox";
import { fetchZlBoxData } from "../../redux/data/zlBoxDataActions"
import { fetchHumanZlBoxData } from "../../redux/data/humanData/humanZlBoxDataActions"
import LowBalancePopups from "../Popups/LowBalancePopups";
import Tost from "../Tost";

const GetZlBox = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const zlBoxData = useSelector((state) => state.zamiataLandBox);
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [confirmPupup, setconfirmPupup] = useState(false)
    const [boxDetails, setBoxDetails] = useState('')
    const [boxName, setBoxName] = useState('')


    async function buyZlBox() {
        const gasPrice = await blockchain.web3.eth.getGasPrice()
        // const boxPriceFromWei = (blockchain.web3.utils.fromWei(zlBoxData.zlBox._zamBoxPrice, 'ether'))
        // const gasPriceFromWei = (blockchain.web3.utils.fromWei(gasPrice, 'ether'))
        // const totalAmount = (parseFloat(boxPriceFromWei) + parseFloat(gasPriceFromWei))
        // const totalAmountToWei = (blockchain.web3.utils.toWei(totalAmount.toString(), 'ether'))
        const totalAmount = (parseInt(zlBoxData.zlBox._zamBoxPrice)) + (parseInt(gasPrice))
        if (walletBal.walletBalance >= totalAmount) {
            setconfirmPupup(true)
            //setThisLoading(false)
            try {
                await blockchain.zlBoxContract.methods.mintBox(blockchain.account)
                    .send({
                        gasPrice: gasPrice,
                        from: blockchain.account,
                        value: zlBoxData.zlBox._zamBoxPrice,
                    })
                //setLandDetailsPopuo(false)
                Tost("success", "Transaction successful")
                dispatch(fetchZlBoxData());
                dispatch(fetchHumanZlBoxData(blockchain.account));
            } catch (error) {
                if (error.code === 4001) {
                    Tost("error", "Denied transaction signature.")
                } else {
                    Tost("error", error.message)
                }
            }
            setconfirmPupup(false)
        } else {
            setLoawBalancePopup(true)
        }

    }

    return (
        <>
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            {confirmPupup && <ConfirmationPopupBox bd={boxDetails} bn={boxName} />}
            <div className="animate__animated animate__fadeIn flex flex-col sm:flex-row   bg-white rounded-2xl shadow-lg">
                <div className="w-full flex flex-col justify-between space-y-2 md:space-y-4">
                    <div className="ml-2 mt-2 ">
                        <p className=" font-Archivo font-extrabold text-gray-700 text-xl md:text-xl lg:text-2xl">Land Box</p>
                        <p className=" font-Inter md:text-xs lg:text-sm text-gray-600">A random-sized land and water source are included in each box. The more land you have, the more crops you can cultivate.</p>
                    </div>

                    <div className="flex flex-col space-x-2 ">
                        <div className="flex">
                            <p className=" bg-gray-700 p-2  rounded-r-md text-white text-base md:text-xs lg:text-sm font-Inter font-bold  ">10K Limited Supply</p>

                        </div>
                        <div className=" space-x-2 md:text-sm lg:text-base  flex items-center ">
                            <p className=" font-Inter text-purple-600  font-bold">
                                Price
                            </p>
                            {
                                !zlBoxData.loading ?
                                    <div className="animate__animated animate__fadeIn flex items-center space-x-1">
                                        <div>
                                            <span className="text-purple-600  font-bold">{blockchain.web3.utils.fromWei(zlBoxData.zlBox._zamBoxPrice, 'ether')}</span>
                                        </div>
                                        <div>
                                            <img className=" w-4 md:w-3 lg:w-4"
                                                src={polygonIcon} alt="polygon-icon" />
                                        </div>

                                    </div>
                                    : (<div><BeatLoader color="#bcbcbc" size={7} /></div>)

                            }
                        </div>
                    </div>
                </div>
                <div className="p-2 sm:w-1/2 md:w-2/3 lg:w-1/2 flex flex-col justify-center items-center space-y-2 ">
                    <div className=" flex relative justify-center items-end">
                        <img className="  rounded-xl "
                            src={require('../../Assets/ZamBox/land-zam-box.png')} alt="zam-box" />
                        <div className=" flex absolute p-1 ">
                            <div className=" items-center space-x-1 flex rounded-lg text-gray-300 text-lg  sm:text-xs  font-Inter font-bold  ">
                                <p className="">
                                    Minted
                                </p>
                                {
                                    !zlBoxData.loading ?
                                        <p className="animate__animated animate__fadeIn">
                                            {zlBoxData.zlBox._totalZamBoxMinted}/10K
                                        </p>
                                        : (<div><BeatLoader color="#bcbcbc" size={5} /></div>)
                                }

                            </div>
                        </div>
                    </div>

                    <button
                        onClick={() => {
                            setBoxDetails(zlBoxData.zlBox)
                            setBoxName("Land Box")
                            buyZlBox()
                        }}
                        className="  w-full py-3 sm:py-2 rounded-lg  bg-orange-600 md:text-xs lg:text-sm font-Inter font-bold text-white hover:bg-blue-600 duration-500">
                        Purchase now
                    </button>
                </div>


            </div>
        </>

    )
}
export default GetZlBox;