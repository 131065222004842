import store from "../../store";

const fetchHumanStorageBuildingDataRequest = () => {
    return {
        type: "CHECK_HUMAN_STROGE_BUILDING_REQUEST",
    };
};

const fetchHumanStorageBuildingDataSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_STROGE_BUILDING_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchHumanStorageBuildingDataFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_STROGE_BUILDING_FAILED",
        payload: payload,
    };
};

export const fetchHumanStorageBuildingData = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanStorageBuildingDataRequest());
        try {

            let storageBuildingBalance = 0;
            let ownerStorageBuilding = [{
                id: "",
                address: '',
                buildings: ""
            }]

            const barnAddress = await store
                .getState()
                .blockchain.barnContract._address
            const barn = await store
                .getState()
                .blockchain.barnContract.methods.getOwnerBalance(account)
                .call();
            if (barn.length > 0) {
                storageBuildingBalance = storageBuildingBalance + barn.length
                for (var i = 0; i < barn.length; i++) {
                    const buildingDataBase64 = await store
                        .getState()
                        .blockchain.barnContract.methods.tokenURI(barn[i])
                        .call();
                    const json = atob(buildingDataBase64.substring(29));
                    const result = JSON.parse(json);
                    //console.log(result);
                    ownerStorageBuilding.push({
                        id: barn[i],
                        address: barnAddress,
                        buildings: result
                    })
                }
            }

            const waterTankAddress = await store
                .getState()
                .blockchain.waterTankContract._address
            const waterTank = await store
                .getState()
                .blockchain.waterTankContract.methods.getOwnerBalance(account)
                .call();
            if (waterTank.length > 0) {
                storageBuildingBalance = storageBuildingBalance + waterTank.length
                for (var i = 0; i < waterTank.length; i++) {
                    const buildingDataBase64 = await store
                        .getState()
                        .blockchain.waterTankContract.methods.tokenURI(waterTank[i])
                        .call();
                    const json = atob(buildingDataBase64.substring(29));
                    const result = JSON.parse(json);
                    ownerStorageBuilding.push({
                        id: waterTank[i],
                        address: waterTankAddress,
                        buildings: result
                    })
                }
            }

            const siloAddress = await store
                .getState()
                .blockchain.siloContract._address
            const silo = await store
                .getState()
                .blockchain.siloContract.methods.getOwnerBalance(account)
                .call();

            if (silo.length > 0) {
                storageBuildingBalance = storageBuildingBalance + silo.length
                for (var i = 0; i < silo.length; i++) {
                    const buildingDataBase64 = await store
                        .getState()
                        .blockchain.siloContract.methods.tokenURI(silo[i])
                        .call();
                    const json = atob(buildingDataBase64.substring(29));
                    const result = JSON.parse(json);
                    //console.log(result);
                    ownerStorageBuilding.push({
                        id: silo[i],
                        address: siloAddress,
                        buildings: result
                    })
                }
            }
            // console.log(storageBuildingBalance)
            // console.log(ownerStorageBuilding)

            // if (storageBuildingBalance.length > 0) {
            //     for (var i = 0; i < storageBuildingBalance.length; i++) {
            //         const landDataBase64 = await store
            //             .getState()
            //             .blockchain.barnContract.methods.tokenURI(storageBuildingBalance[i])
            //             .call();
            //         const json = atob(landDataBase64.substring(29));
            //         const result = JSON.parse(json);

            //         //console.log(result);
            //         ownerStorageBuilding.push(result)
            //     }
            // }
            // console.log(ownerStorageBuilding)

            dispatch(
                fetchHumanStorageBuildingDataSuccess({
                    storageBuildingBalance,
                    ownerStorageBuilding,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanStorageBuildingDataFailed("Could not load data from contract."));
        }
    };
};