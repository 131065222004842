import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiFillCloseCircle } from 'react-icons/ai'
import { fetchHumanZlBoxData } from "../../redux/data/humanData/humanZlBoxDataActions"
import { fetchHumanLandData } from "../../redux/data/humanData/humanLandDataActions"
import { fetchHumanStorageBuildingData } from "../../redux/data/humanData/humanStorageBuildingDataActions"


const OpenBoxPopup = ({ bName, txInfo, userWalletAddress, closeBoxPopup, bid }) => {
    const blockchain = useSelector((state) => state.blockchain);
    const dispatch = useDispatch();
    const landData = useSelector((state) => state.landData);
    //console.log(landData.allLands[txInfo.returnValues._landid])
    return (
        <div className=" bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            {bName === 'zlBox' &&
                <div className='bg-white w-full max-w-sm rounded-xl shadow-xl p-2 mx-2'>
                    {
                        txInfo === null ?
                            null
                            : (
                                <div className="animate__animated animate__fadeIn flex justify-end">
                                    <button
                                        onClick={() => {
                                            dispatch(fetchHumanZlBoxData(userWalletAddress))
                                            dispatch(fetchHumanLandData(userWalletAddress))
                                            closeBoxPopup(false)
                                        }}
                                        className="cursor-pointer text-gray-700 hover:text-gray-600  duration-500  flex justify-end items-end">
                                        <AiFillCloseCircle size={25} />
                                    </button>
                                </div>
                            )
                    }
                    <div className="flex flex-col  justify-center px-2 mt-10">
                        {
                            txInfo === null ?
                                <>
                                    <div className="flex justify-center items-center">
                                        <div className="absolute z-0  w-32 h-32 rounded-full animate-spin border-4 border-solid border-t-orange-600 border-r-orange-600 border-b-orange-600">
                                        </div>

                                        <div className="rounded-full flex inset-0  z-10">
                                            <img className=" w-28 m-2 rounded-full "
                                                src={require('../../Assets/ZamBox/land-zam-box.png')} alt="zam-box" />
                                        </div>
                                    </div>
                                    <div className="flex  text-center justify-center px-8 mt-8">
                                        <span className=" font-Archivo text-3xl font-extrabold text-gray-800">Wating for <br /> confirmation</span>

                                    </div>
                                    <div className="px-8 mt-4 mb-8 text-center text-lg">
                                        <span className=" font-Inter text-gray-800" >You are opening<br /> <span className=" text-orange-600 font-bold">Land Box #{bid}</span></span>
                                    </div>
                                </>

                                : (
                                    <div className=" animate__animated animate__fadeIn flex flex-col text-center">
                                        <p className=" font-Archivo font-bold text-gray-800 text-2xl mb-4">Congratulation</p>
                                        <div className=" flex justify-center items-center text-center ">
                                            <div className="flex flex-col overflow-hidden rounded-lg border-2 border-blue-500">
                                                <img className=" w-40"
                                                    src={require('../../Assets/Lands/farmland-' + txInfo.returnValues._landid + '.png')} alt="land" />                                        </div>

                                        </div>
                                        <div className="flex mb-8 text-center justify-center px-8 mt-4">
                                            <span className=" font-Archivo text-3xl font-extrabold text-slate-800">You got a <br /> <span className=" text-2xl text-orange-600"> {blockchain.web3.utils.fromWei(landData.allLands[txInfo.returnValues._landid].landSize, 'mwei')} Acres of farmland</span> </span>
                                        </div>
                                    </div>

                                )
                        }
                    </div>
                </div>
            }

            {bName === 'zsbBox' &&
                <div className='bg-white w-full max-w-sm rounded-xl shadow-xl p-2 mx-2'>
                    {
                        txInfo === null ?
                            null
                            : (
                                <div className="animate__animated animate__fadeIn flex justify-end">
                                    <button
                                        onClick={() => {
                                            dispatch(fetchHumanZlBoxData(userWalletAddress))
                                            dispatch(fetchHumanStorageBuildingData(userWalletAddress))
                                            closeBoxPopup(false)
                                        }}
                                        className="cursor-pointer text-gray-700 hover:text-gray-600  duration-500  flex justify-end items-end">
                                        <AiFillCloseCircle size={25} />
                                    </button>
                                </div>
                            )
                    }
                    <div className="flex flex-col  justify-center px-2 mt-10">
                        {
                            txInfo === null ?
                                <>
                                    <div className="flex justify-center items-center">
                                        <div className="absolute z-0  w-32 h-32 rounded-full animate-spin border-4 border-solid border-t-orange-600 border-r-orange-600 border-b-orange-600">
                                        </div>

                                        <div className="rounded-full flex inset-0  z-10">
                                            <img className=" w-28 m-2 rounded-full "
                                                src={require('../../Assets/ZamBox/building-zam-box.png')} alt="zam-box" />
                                        </div>
                                    </div>
                                    <div className="flex  text-center justify-center px-8 mt-8">
                                        <span className=" font-Archivo text-3xl font-extrabold text-gray-800">Wating for <br /> confirmation</span>

                                    </div>
                                    <div className="px-8 mt-4 mb-8 text-center text-lg">
                                        <span className=" font-Inter text-gray-800" >You are opening<br /> <span className=" text-orange-600 font-bold">Storage Bulding Box #{bid}</span></span>
                                    </div>
                                </>

                                : (
                                    <div className=" animate__animated animate__fadeIn flex flex-col text-center">
                                        <p className=" font-Archivo font-bold text-gray-800 text-2xl mb-4">Congratulation</p>
                                        <div className=" flex justify-center items-center text-center ">
                                            <div className="flex flex-col overflow-hidden rounded-lg border-2 border-blue-500">
                                                <img className=" w-40"
                                                    src={require('../../Assets/Storage_Buildings/' + txInfo.returnValues._buildingName + '.png')} alt="land" />                                        </div>

                                        </div>
                                        <div className="flex mb-8 text-center justify-center px-8 mt-4">
                                            <span className=" font-Archivo text-3xl font-extrabold text-slate-800">You got a <br />
                                                {txInfo.returnValues._buildingName === 'barn' &&
                                                    <span className=" text-2xl text-orange-600">Bran</span>
                                                }
                                                {txInfo.returnValues._buildingName === 'water-tank' &&
                                                    <span className=" text-2xl text-orange-600">Water Tank</span>
                                                }
                                                {txInfo.returnValues._buildingName === 'water-tank' &&
                                                    <span className=" text-2xl text-orange-600">Silo</span>
                                                }

                                            </span>
                                        </div>
                                    </div>

                                )
                        }
                    </div>
                </div>
            }

        </div>
    )
}
export default OpenBoxPopup;