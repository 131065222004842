import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import createImage from '../../Assets/Elements/create-img.png'
import { AiFillCloseCircle } from 'react-icons/ai'
import { fetchHumanData } from '../../redux/data/humanDataActions'



const CreateAccountPopup = ({ closeModel, doRefesh, closeLowBalancePopUp }) => {
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const dispatch = useDispatch();
    const [error, setError] = useState(false)
    const [hideImage, setHideImage] = useState(true)
    const [userInfo, setUserInfo] = useState({
        firstname: '',
        lastname: '',
        gender: '',
    })

    const handelInput = (e) => {
        const name = e.target.name
        const value = e.target.value.replace(/[^a-zA-Z]/ig, '').charAt(0).toUpperCase() + e.target.value.slice(1)
        setUserInfo({ ...userInfo, [name]: value })
        setError(false)
    }

    const handelSubmit = async (e) => {
        e.preventDefault()
        var genderid;
        const gasPrice = await blockchain.web3.eth.getGasPrice()
        if (walletBal.walletBalance >= gasPrice) {
            if (userInfo.gender !== '') {
                setHideImage(false)
                if (userInfo.gender === 'Male') {
                    genderid = 0
                }
                else if (userInfo.gender === 'Female') {
                    genderid = 1
                }

                try {
                    await blockchain.userAccount.methods.createAccount(userInfo.firstname.toLowerCase(), userInfo.lastname.toLowerCase(), genderid)
                        .send({
                            gasPrice: gasPrice,
                            from: blockchain.account
                        })
                    dispatch(fetchHumanData(blockchain.account));
                    closeModel(false)
                    doRefesh('refresh')
                } catch (error) {
                    console.log("Error: ", error);
                    setHideImage(true)

                }
                setHideImage(true)
            } else {
                setError(true)

            }
        }
        else {
            closeLowBalancePopUp(true)
        }
    }

    return (
        <>

            <div className="  bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
                <div className=' bg-aquamarine-blue-500  overflow-hidden max-w-sm sm:max-w-lg rounded-xl shadow-xl  mx-2'>
                    <div className="flex w-full justify-between bg-slate-800 items-center py-2 px-5 ">
                        <span className=" font-bold font-Archivo text-xl text-orange-600">Create your account</span>
                        {hideImage && <button
                            onClick={() => closeModel(false)}
                            className="cursor-pointer text-gray-300 hover:text-gray-500  duration-500  flex justify-end items-end">
                            <AiFillCloseCircle size={25} />
                        </button>}

                    </div>
                    <div className="flex flex-col sm:flex-row font-Archivo rounded-xl my-5  justify-center">
                        <div className="flex w-full pt-2 pb-5  overflow-hidden justify-center items-center">
                            {
                                hideImage ?
                                    <img className="w-2/5 sm:w-auto" src={createImage} alt="banner" />
                                    : (

                                        <div className="flex w-full justify-center items-center">
                                            <div className="absolute z-0 w-24 h-24
                                         rounded-full animate-spin border-4 border-solid border-t-orange-600 border-r-orange-600 border-b-orange-600">
                                            </div>

                                            <div className="rounded-full flex flex-col justify-center bg-white w-24 h-24 text-center">
                                                <span className=" font-Archivo  text-xl text-gray-800">Hello!</span>
                                                <span className=" font-Archivo font-bold text-xl text-gray-800">{userInfo.firstname}</span>

                                            </div>
                                        </div>
                                    )
                            }

                        </div>
                        <form action="" onSubmit={handelSubmit} className="flex flex-col w-full my-1 px-5 sm:px-0  sm:pr-4">
                            <div className=" space-y-3">
                                <div className=" flex flex-row space-x-3">
                                    <div>
                                        <label htmlFor="firstname" className=" font-bold text-white">First Name</label>
                                        <input
                                            required minLength={4} maxLength={8}
                                            type='text' name='firstname' id="firstname"
                                            className=" font-Inter text-gray-700 focus:outline-none bg-white bg-opacity-40 text-xl w-full rounded-lg pl-1"
                                            placeholder=''
                                            value={userInfo.firstname}
                                            onChange={handelInput}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="lastname" className=" font-bold text-white">Last Name</label>
                                        <input
                                            required minLength={4} maxLength={8}
                                            type='text' name='lastname' id="lastname"
                                            className=" font-Inter text-gray-700 focus:outline-none bg-white bg-opacity-40 text-xl w-full rounded-lg pl-1"
                                            placeholder=''
                                            value={userInfo.lastname}
                                            onChange={handelInput}
                                        />
                                    </div>
                                </div>


                                <div className="flex  space-x-2 items-center ">
                                    <label htmlFor="gender" className=" font-bold text-white">Gender</label>
                                    <div className=" space-x-1 items-center">
                                        <input onChange={handelInput} type='radio' value='Male' name='gender'></input>
                                        <span className=" font-Inter text-gray-700">Male</span>
                                    </div>
                                    <div className="space-x-1 items-center">
                                        <input onChange={handelInput} type='radio' value='Female' name='gender'></input>
                                        <span className=" font-Inter text-gray-700">Female</span>
                                    </div>
                                </div>
                                {
                                    error && <span className=" text-xs text-red-700">Choose your gender</span>
                                }


                            </div>
                            {
                                hideImage ?
                                    <div className="my-2 text-center">
                                        <button type="submit" className="w-auto mt-1   px-3  py-1  rounded-lg bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600 hover:text-white  duration-500 ">
                                            Create account
                                        </button>
                                    </div>
                                    :
                                    (null)
                            }


                        </form>

                    </div>
                    {
                        hideImage ?
                            null
                            : (
                                <div className=" m-2 flex justify-center font-Inter font-bold text-gray-700">
                                    please wait for your account confirmation
                                </div>
                            )

                    }

                </div>
            </div>
        </>

    )
}
export default CreateAccountPopup;