const initialState = {
    loading: true,
    zamTokenBalance: null,
    error: false,
    errorMsg: "",
};

const humanZamTokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_HUMAN_ZAMTOKEN_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_HUMAN_ZAMTOKEN_SUCCESS":
            return {
                ...initialState,
                loading: false,
                zamTokenBalance: action.payload.zamTokenBalance,
            };
        case "CHECK_HUMAN_ZAMTOKEN_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default humanZamTokenReducer;