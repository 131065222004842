const initialState = {
    loading: true,
    allLands: [],
    isDirectMint: null,
    error: false,
    errorMsg: "",
};

const landDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_DATA_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                allLands: action.payload.allLands,
                isDirectMint: action.payload.isDirectMint,

            };
        case "CHECK_DATA_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default landDataReducer;