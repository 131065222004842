import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import { AiFillCloseCircle } from 'react-icons/ai'
import Tost from "../Tost";
import BeatLoader from "react-spinners/BeatLoader";
import { fetchHumanZamToken } from "../../redux/data/humanData/humanZamTokenActions"



const PresellZamTokenBuyPopup = ({ buyPrice, min, max, closePresellPopop, closeLowBalancePopUp }) => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const [buyAmount, setbuyAmount] = useState('')
    const [txMessade, settxMessade] = useState('opacity-0')
    const [totalMaticPay, settotalMaticPay] = useState('')
    const [errorMessage, setErroeMessage] = useState({
        msg: '',
        opacity: 'opacity-0'
    })
    const [buttonChange, setbuttonChange] = useState({
        background: 'bg-gray-500',
        isDesibled: true,
        isLoading: false
    })
    const handelInput = (e) => {
        const tokenAmount = e.target.value
        setbuyAmount(tokenAmount)
        if (tokenAmount >= min && tokenAmount <= max) {
            settxMessade('opacity-100')
            const pay = buyPrice * tokenAmount
            settotalMaticPay(pay.toFixed(2))
            setErroeMessage({ msg: '', opacity: 'opacity-0' })
            setbuttonChange({ background: 'bg-orange-600 hover:bg-blue-600', isDesibled: false })
        } else {
            setbuttonChange({ background: 'bg-gray-500', isDesibled: true })
            settxMessade('opacity-0')
            if (tokenAmount > max) {
                setErroeMessage({ msg: 'More than ' + max + ' tokens cannot be purchased at once.', opacity: 'opacity-100' })
            }
            else if (tokenAmount < min) {
                setErroeMessage({ msg: 'You must purchase a minimum of ' + min + ' tokens.', opacity: 'opacity-100' })
            }
        }
    }
    async function buyToken() {

        const gasPrice = await blockchain.web3.eth.getGasPrice()
        const payMatic = buyPrice * buyAmount
        const payMaticInWei = blockchain.web3.utils.toWei((payMatic).toString(), 'ether')
        const tokenInWei = blockchain.web3.utils.toWei(buyAmount, 'ether')
        const totalAmount = (parseInt(payMaticInWei)) + (parseInt(gasPrice))
        if (walletBal.walletBalance >= totalAmount) {
            setbuttonChange({ background: 'bg-gray-100', isDesibled: true, isLoading: true })
            try {
                await blockchain.presllZamTokenContract.methods.buy(tokenInWei)
                    .send({
                        gasPrice: gasPrice,
                        from: blockchain.account,
                        value: payMaticInWei,
                    })
                Tost("success", "Transaction successful")
                setbuttonChange({ background: 'bg-orange-600 hover:bg-blue-600', isDesibled: false, isLoading: false })

                dispatch(fetchHumanZamToken(blockchain.account));
            } catch (error) {
                if (error.code === 4001) {
                    Tost("error", "Denied transaction signature.")
                } else {
                    Tost("error", error.message)
                }
            }
            setbuttonChange({ background: 'bg-orange-600 hover:bg-blue-600', isDesibled: false, isLoading: false })
        } else {
            closeLowBalancePopUp(true)
        }

    }
    return (

        <div className=" bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className='bg-white w-full max-w-sm rounded-xl shadow-xl p-2 mx-2'>
                <div className="flex w-full justify-end items-center p-2 ">
                    <button

                        onClick={() => {
                            closePresellPopop(false)

                        }}
                        className=" cursor-pointer text-gray-500 hover:text-gray-700  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={30} />
                    </button>
                </div>
                <div className="flex flex-col  justify-center px-2 mt-4">
                    <div className="flex flex-col justify-center items-center">
                        <img className=" w-20  rounded-xl "
                            src={require('../../Assets/Tokens/zam-token-500x500.png')} alt="zam-token" />
                        <p className=" font-Archivo font-extrabold text-gray-700 text-sm sm:text-lg ">Zamiata Token - ZAM</p>
                    </div>
                    <div className=" text-xs sm:text-sm  flex justify-center items-center space-x-1  ">
                        <p className=" font-Inter font-bold text-gray-500 ">Price 100 ZAM /</p>
                        <div className=" flex items-center space-x-1  ">
                            <span className=" font-Inter   font-bold text-purple-600 ">{buyPrice * 100} </span>
                            <img className=" w-3"
                                src={polygonIcon} alt="polygon-icon" />
                            <span className=" font-Inter   font-bold text-purple-600 ">MATIC </span>
                        </div>
                    </div>
                    <div className=" m-1 flex flex-col justify-center items-center  ">
                        <div>
                            <p className=" font-Archivo font-semibold text-gray-500 text-sm ">Amount</p>
                            <div className="flex flex-row space-x-2 items-center">
                                <div className="bg-gray-200 rounded-md py-1 pr-2 overflow-hidden flex items-centers justify-center">
                                    <input className="bg-gray-200 w-36  focus:outline-none   px-1 "
                                        type="number"
                                        value={buyAmount}
                                        onChange={handelInput}
                                    />
                                    <p className="pointer-events-none  text-gray-400">ZAM</p>
                                </div>
                                <div className="flex">
                                    <button
                                        onClick={buyToken}
                                        disabled={buttonChange.isDesibled}
                                        className={`font-Archivo font-semibold rounded-md text-white py-1 px-2 ${buttonChange.background}   hover:text-white  duration-500`}>
                                        {!buttonChange.isLoading ?
                                            "Purchase"
                                            : (<div><BeatLoader color="#bcbcbc" size={7} /></div>)
                                        }


                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" h-6">
                            <p className={`${errorMessage.opacity} duration-300 font-Inter font-semibold text-xs text-red-400 p-1`}>{errorMessage.msg}</p>

                        </div>
                        <div className={` ${txMessade} duration-500 mb-2 text-xs sm:text-sm  flex flex-col justify-center items-center`}>
                            <p className=" font-Inter  text-gray-400 ">To perform this transaction,</p>
                            <div className="flex space-x-1">
                                <p className=" font-Inter  text-gray-400 "> you must spend</p>
                                <div className=" flex items-center space-x-1  ">
                                    <span className=" font-Inter   font-bold text-purple-600 ">{totalMaticPay} </span>
                                    <img className=" w-3"
                                        src={polygonIcon} alt="polygon-icon" />
                                    <span className=" font-Inter   font-bold text-purple-600 ">MATIC <span className="text-gray-600">+ gas fee</span> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default PresellZamTokenBuyPopup;