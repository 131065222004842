import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

const ImageSwiper = () => {
    return (

        <>
            <Swiper

                navigation={true}

                modules={[Navigation]}
                className=" mySwiper mx-1 md:mx-5  py-3"
            >
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Lands/farmland-0.png')} alt="farmland" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">1 Acres of farmland</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">You can cultivate this land using seeds and water to start growing crops. The chosen seeds determine when to harvest. Alternatively, use it to build places where food, water, and crops can be kept. Water sources do not exist on this land.</p>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Lands/farmland-1.png')} alt="farmland" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">2 Acres of farmland</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">You can cultivate this land using seeds and water to start growing crops. The chosen seeds determine when to harvest. Alternatively, use it to build places where food, water, and crops can be kept. Water sources do not exist on this land.</p>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Lands/farmland-2.png')} alt="farmland" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">2 Acres of farmland</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">You can cultivate this land using seeds and water to start growing crops. The chosen seeds determine when to harvest. Alternatively, use it to build places where food, water, and crops can be kept. Only a water pump can reach the water supply on this land.</p>
                        </div>

                    </div>
                </SwiperSlide>
                <SwiperSlide className=" w-full ">
                    <div className=" space-y-2 ">
                        <div className=" items-center flex justify-center ">
                            <img className=" w-3/6 rounded-lg"
                                src={require('../Assets/Lands/farmland-3.png')} alt="farmland" />
                        </div>

                        <div className="  text-center  flex flex-col justify-center items-center">
                            <p className="mx-2 text-center font-Inter md:text-base lg:text-lg font-bold text-gray-300">4 Acres of farmland</p>
                            <p className=" font-Inter text-xs lg:text-sm text-gray-500">You can cultivate this land using seeds and water to start growing crops. The chosen seeds determine when to harvest. Alternatively, use it to build places where food, water, and crops can be kept. On this land, two separate sources of water can be accessed via wells or pumps.</p>
                        </div>

                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    )
}
export default ImageSwiper;