const initialState = {
    loading: true,
    zlBox: null,
    error: false,
    errorMsg: "",
};

const zlBoxDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_ZLBOX_DATA_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_ZLBOX_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                zlBox: action.payload.zlBox,
                //landOwner: action.payload.allOwnerLips,
            };
        case "CHECK_ZLBOX_DATA_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default zlBoxDataReducer;