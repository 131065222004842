import Web3 from "web3";
import LAND from "../../contracts/landContracts/ZamiataLands.json";
import FARMLANDDATA from "../../contracts/landContracts/FarmlandData.json"
import BARN from "../../contracts/storageBuildings/barn/ZamiataBarn.json"
import BARNDATA from "../../contracts/storageBuildings/barn/BarnData.json"
import WATERTANK from "../../contracts/storageBuildings/waterTank/ZamiataWaterTank.json"
import SILO from "../../contracts/storageBuildings/silo/ZamiataSilo.json"
import WATERTANKDATA from "../../contracts/storageBuildings/waterTank/WaterTankData.json"
import SILODATA from "../../contracts/storageBuildings/silo/SiloData.json"
import ZLBOX from "../../contracts/zamBoxContacts/ZamiataLandBox.json"
import ZSBBOX from "../../contracts/storageBuildings/ZamiataStorageBuildingsBox.json"
import HUMANS from "../../contracts/humanDataContracts/ZamiataHumans.json";
import ZAMIATATOKEN from "../../contracts/Tokens/Zamiata.json"
import PRESELLZAMTOKEN from "../../contracts/Tokens/ZamiataPresell.json"
import { fetchHumanData } from "../data/humanDataActions"
import { fetchHumanZlBoxData } from "../data/humanData/humanZlBoxDataActions"
import { fetchHumanWalletBalance } from "../data/humanData/humanBalanceActions"

const connectRequest = () => {
    return {
        type: "CONNECTION_REQUEST",
    };
};
const connectSuccess = (payload) => {
    return {
        type: "CONNECTION_SUCCESS",
        payload: payload,
    };
};

const connectFailed = (payload) => {
    return {
        type: "CONNECTION_FAILED",
        payload: payload,
    };
};
const updateAccountRequest = (payload) => {
    return {
        type: "UPDATE_ACCOUNT",
        payload: payload,
    };
};

export const connect = () => {
    return async (dispatch) => {
        dispatch(connectRequest());
        if (window.ethereum) {
            let web3 = new Web3(window.ethereum);
            try {

                const accounts = await window.ethereum.request({
                    method: "eth_accounts",
                });
                //console.log(accounts[0])


                const networkId = await window.ethereum.request({
                    method: "net_version",
                });

                //console.log("NetworkId: ", networkId);
                window.ethereum.on("chainChanged", () => {
                    window.location.reload();
                });
                if (accounts[0] !== undefined) {
                    // if (networkId === '137') {
                    //     dispatch(fetchHumanWalletBalance(accounts[0]));

                    //     const landContract = new web3.eth.Contract(
                    //         LAND.abi,
                    //         '0x05E9123Fb913B6A7B5D7FDf3ecc8af486F6b06d1'
                    //     );
                    //     const farmlandDataContract = new web3.eth.Contract(
                    //         FARMLANDDATA.abi,
                    //         "0x3B3f9f4046453C0d2E204F0567272E5277865187"
                    //     );
                    //     const zamiataLandBoxContract = new web3.eth.Contract(
                    //         ZLBOX.abi,
                    //         "0x4D172E8C2557694Cf37a75D65e69e8BBDa21736a"
                    //     );
                    //     const userAccountContract = new web3.eth.Contract(
                    //         HUMANS.abi,
                    //         "0x855df2d9c28aCF89f5C1F435a10BDCDc7e9B6D0f"
                    //     );
                    //     dispatch(
                    //         connectSuccess({
                    //             openSeaAssetLink: 'https://opensea.io/assets/matic/',
                    //             account: accounts[0],
                    //             userAccount: userAccountContract,
                    //             web3: web3,
                    //             lands: landContract,
                    //             farmlandData: farmlandDataContract,
                    //             zlBox: zamiataLandBoxContract,
                    //             networkid: networkId,
                    //             humanDna: "humanData.dna",
                    //             isAvatarMint: "avatarMinted",
                    //         })
                    //     );
                    //     // Add listeners start
                    //     window.ethereum.on("accountsChanged", (accounts) => {
                    //         dispatch(updateAccount(accounts[0]));
                    //     });
                    //     window.ethereum.on("chainChanged", () => {
                    //         window.location.reload();
                    //     });
                    //     // Add listeners end
                    // }
                    if (networkId === '137') {
                        dispatch(fetchHumanWalletBalance(accounts[0]));

                        const landContract = new web3.eth.Contract(
                            LAND.abi,
                            "0x05E9123Fb913B6A7B5D7FDf3ecc8af486F6b06d1", // polygon mainnet
                            //"0x10AB721C218c4f758F54CB405BE2B6270C7fc852" // polygon testnet
                        );
                        const farmlandDataContract = new web3.eth.Contract(
                            FARMLANDDATA.abi,
                            "0x3B3f9f4046453C0d2E204F0567272E5277865187",// polygon mainnet
                            //"0xe3c567Ad0C0aa171400C4d8Ad42419DB65Aa98Fa" // polygon testnet
                        );

                        const barnContract = new web3.eth.Contract(
                            BARN.abi,
                            "0xD9390a9d1d8E33892b5e537F715bE49A193709dc", // polygon mainnet
                            //"0x2D4346554f7FfcFd5a2c6c9140D9ED66AE02E311" // polygon testnet
                        );

                        const barnDataContract = new web3.eth.Contract(
                            BARNDATA.abi,
                            "0x61D8800711b5B9a442B4b653dD05c77E2E4fD93e", // polygon mainnet
                            //"0x82884AF98a4e353C62D5c4dAB0300fed55dE34A3" // polygon testnet
                        );
                        const waterTankContract = new web3.eth.Contract(
                            WATERTANK.abi,
                            "0x4DFd3d4C724711d9218Ea9FD8fc76071AfEc380F", // polygon mainnet
                            //"0xA8a467478564aaAA025215B5f8F773859A2B8A45" // polygon testnet
                        );
                        const waterTankDataContract = new web3.eth.Contract(
                            WATERTANKDATA.abi,
                            "0x80c8783208EbE819a5424B5b069d832F78EB9EB4", // polygon mainnet
                            //"0x1D61c82Dd2Af2d6dCeFB2cD80DDBEfB5E370DBda" // polygon testnet
                        );

                        const siloContract = new web3.eth.Contract(
                            SILO.abi,
                            "0x88CF37F05a9DF61123dB3A194920FC7c19Ba5292",// polygon mainnet
                            //"0x3ee2b36270BBFb628018847a9332de42F6EBcD38" // polygon testnet
                        );

                        const siloDataContract = new web3.eth.Contract(
                            SILODATA.abi,
                            "0xDc8b388b8b2d378d925084E7BfCfaC38FD511917", // polygon mainnet
                            //"0xa3E97953B78f07763d245F425B822F158E1937FE" // polygon testnet
                        );

                        const zamiataLandBoxContract = new web3.eth.Contract(
                            ZLBOX.abi,
                            "0x4D172E8C2557694Cf37a75D65e69e8BBDa21736a", // polygon mainnet
                            //"0x3B2959254a0ab7C95fDB86d2c345e272ce588D8A" // polygon testnet
                        );
                        const zamiataStorageBuildingBox = new web3.eth.Contract(
                            ZSBBOX.abi,
                            "0xCf8339428929E52b200FfdbD0bf7f0d0a7cFD9bD", // polygon mainnet
                            //"0xCB2952F4B4091121EE534dE8E164ad32B5ceeC37" // polygon testnet
                        );
                        const userAccountContract = new web3.eth.Contract(
                            HUMANS.abi,
                            "0x855df2d9c28aCF89f5C1F435a10BDCDc7e9B6D0f", // polygon mainnet
                            //"0x89c20951519AE3201e3CDc02aaAC7b288317aa98" // polygon testnet
                        );

                        const zamiataTokenContract = new web3.eth.Contract(
                            ZAMIATATOKEN.abi,
                            "0x7b0295210011003E5661aFbFF141845eEFB01DE9", // polygon mainnet
                            //"0xC94Cc261655336Aa28432A85463c66278523E5c5" // polygon testnet
                        );
                        const presllZamTokenContract = new web3.eth.Contract(
                            PRESELLZAMTOKEN.abi,
                            "0x8fE55a8Bd94b35D379378Aad88570a41aa9575dc", // polygon mainnet
                            //"0x5C3b159bF36F9B3E766D4E0c2792afB1de5F963B" // polygon testnet
                        );
                        dispatch(
                            connectSuccess({
                                openSeaAssetLink: 'https://opensea.io/assets/matic/',
                                account: accounts[0],
                                userAccount: userAccountContract,
                                web3: web3,
                                lands: landContract,
                                farmlandData: farmlandDataContract,
                                barnContract: barnContract,
                                barnDataContract: barnDataContract,
                                waterTankContract: waterTankContract,
                                waterTankDataContract: waterTankDataContract,
                                siloContract: siloContract,
                                siloDataContract: siloDataContract,
                                zlBoxContract: zamiataLandBoxContract,
                                zbsBoxContrat: zamiataStorageBuildingBox,
                                zamTokenContract: zamiataTokenContract,
                                presllZamTokenContract: presllZamTokenContract,
                                networkid: networkId,
                                humanDna: "humanData.dna",
                                isAvatarMint: "avatarMinted",
                            })
                        );
                        // Add listeners start
                        window.ethereum.on("accountsChanged", (accounts) => {
                            dispatch(updateAccount(accounts[0]));
                        });
                        window.ethereum.on("chainChanged", () => {
                            window.location.reload();
                        });
                        // Add listeners end
                    }
                    else {
                        dispatch(connectFailed("Change network to Polygon."));
                    }
                } else {
                    dispatch(connectFailed("Login to metamask."));
                }

            } catch (err) {
                dispatch(connectFailed("Something went wrong."));
            }
        } else {
            dispatch(connectFailed("Install Metamask."));
        }
    };
};
export const updateAccount = (account) => {
    return async (dispatch) => {
        dispatch(updateAccountRequest({ account: account }));
        dispatch(fetchHumanWalletBalance(account));
        dispatch(fetchHumanData(account));
        dispatch(fetchHumanZlBoxData(account));
    };
};
