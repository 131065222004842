import React from "react";
import { AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from "react-redux";


const LandDetailsPopup = ({ mintFun, closeDetailsPopop, ld }) => {
    const blockchain = useSelector((state) => state.blockchain);
    return (
        <div className="   bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className={` bg-white   flex flex-col justify-center items-center  overflow-hidden max-w-sm sm:max-w-lg rounded-xl shadow-xl  mx-2`}>
                <div className="flex w-full justify-between bg-slate-800 items-center py-2 px-5 ">
                    <span className=" font-bold font-Archivo text-xl text-orange-600">{blockchain.web3.utils.fromWei(ld.landSize, 'mwei')} Acres of farmland<span className=" font-medium text-gray-500"></span></span>
                    <button
                        onClick={() => {
                            closeDetailsPopop(false)

                        }}
                        className="cursor-pointer text-gray-300 hover:text-gray-500  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>
                <div className="flex  font-Archivo  rounded-xl  justify-center">
                    <div className="flex flex-col relative w-5/6 sm:w-2/3 m-2 rounded-lg  overflow-hidden justify-end items-center">
                        <img className="" src={ld.imageUriPath} alt="farmland" />
                    </div>
                    <div className=" font-Archivo text-gray-800 text-sm flex  justify-center  w-full px-2">
                        <div className=" mt-4 w-full space-y-1 sm:space-y-2">
                            <div className=" items-center flex justify-between">
                                <span className=" font-bold">Size</span>
                                <span className="font-Inter text-sm text-gray-600">{blockchain.web3.utils.fromWei(ld.landSize, 'mwei')} Acre</span>
                            </div>
                            <div className=" items-center flex justify-between">
                                <span className=" font-bold">Sources of Water</span>
                                <span className=" font-Inter text-sm text-gray-600">{ld.sourcesOfWater}</span>
                            </div>
                            <div className=" items-center flex justify-between">
                                <span className=" font-bold">Number of Pumps</span>
                                <span className="font-Inter text-sm text-gray-600">{ld.noOfPumps}</span>
                            </div>
                            <div className=" items-center flex justify-between">
                                <span className=" font-bold">Number of Wells</span>
                                <span className="font-Inter text-sm text-gray-600">{ld.noOfPond}</span>
                            </div>
                            <div className=" items-center text-purple-600 flex justify-center space-x-2">
                                <span className=" font-bold">Price</span>
                                <span className="font-Inter  text-sm ">{blockchain.web3.utils.fromWei(ld.price, 'ether')} Matic</span>
                            </div>
                            <div className="">
                                <button
                                    disabled
                                    // onClick={() => {
                                    //     mintFun(ld.id, ld.price)
                                    // }}
                                    className="w-full   px-3  py-2  rounded-lg font-Inter font-bold text-white bg-gray-300">
                                    Mint now
                                </button>

                            </div>


                        </div>
                    </div>

                </div>
                <div className=" items-center pb-2 mx-2">
                    <p className=" text-gray-800 font-bold">About this land</p>
                    <p className="font-Inter text-sm text-gray-600">{ld.description}</p>
                </div>
            </div>
        </div>
    )
}
export default LandDetailsPopup;