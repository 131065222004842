const initialState = {
    loading: true,
    storageBuildingBalance: null,
    ownerStorageBuilding: [],
    error: false,
    errorMsg: "",
};

const humanStorageBuildingDataReducers = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_HUMAN_STROGE_BUILDING_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_HUMAN_STROGE_BUILDING_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                storageBuildingBalance: action.payload.storageBuildingBalance,
                ownerStorageBuilding: action.payload.ownerStorageBuilding,
                //landOwner: action.payload.allOwnerLips,
            };
        case "CHECK_HUMAN_STROGE_BUILDING_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default humanStorageBuildingDataReducers;