import React from "react";
import { AiFillCloseCircle } from 'react-icons/ai'
import emptyWallet from "../../Assets/Elements/bankrupt.png"


const LowBalancePopups = ({ closeLowBalancePopUp }) => {
    return (
        <div className=" bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className='bg-white  max-w-sm rounded-xl shadow-xl p-2 mx-2'>

                <div className=" flex justify-end">
                    <button
                        onClick={() => {
                            closeLowBalancePopUp(false)

                        }}
                        className="cursor-pointer text-gray-500 hover:text-gray-700  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>
                <div className=" space-y-5 py-5 flex flex-col justify-center items-center">
                    <p className=" font-Archivo font-extrabold text-2xl text-gray-800">Add Funds</p>
                    <img className="w-20" src={emptyWallet} alt="banner" />
                    <p className=" font-Inter text-gray-600 ">There are not enough funds to complete this transaction.</p>
                    <p className=" font-Inter text-gray-600 ">Add funds to your wallet. Your balance may not refresh for up to a minute.</p>
                </div>
            </div>
        </div>
    )
}
export default LowBalancePopups;