import React from "react";
import { useSelector } from "react-redux";
import openSeaDarkIcon from "../../Assets/Icons/OpenSea-Full-Logo (dark).png"

const UserLands = () => {
    const blockchain = useSelector((state) => state.blockchain);
    const landData = useSelector((state) => state.humanLand);
    //const [comingSoonPopup, setCommingSoonPopup] = useState(false)
    //const [commingSoonMsg, setcommingSoonMsg] = useState('')
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <>
            <div className=" animate__animated animate__fadeIn grid grid-flow-row-dense grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4  p-2 ">
                {
                    landData.ownerLands.map((item, key) => {
                        if (item.id !== "") {
                            return (
                                <div className=" flex justify-center " key={key}>
                                    <div className=" w-5/6 sm:w-44 md:w-52 lg:w-56  flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                                        <div className="relative flex  overflow-hidden justify-center items-end ">
                                            <img className=""
                                                src={item.land.image} alt="farmland" />
                                            <div className="flex p-1 sm:p-1 w-full h-full flex-col justify-between absolute">
                                                <div>
                                                    <button onClick={() => openInNewTab(`${blockchain.openSeaAssetLink}${item.address}/${item.id}`)}>
                                                        <img className=" w-32 sm:w-24 "
                                                            src={openSeaDarkIcon} alt="zam-box" />
                                                    </button>
                                                </div>
                                                <div className="">
                                                    <p className=" font-Inter font-bold text-xl sm:text-sm md:text-base text-center text-gray-700"> {item.land.name}</p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="">
                                            <a href="https://play.zamiata.com/" target="_blank" rel="noopener noreferrer">
                                                <button
                                                    // onClick={() => {
                                                    //     setCommingSoonPopup(true)
                                                    //     setcommingSoonMsg('You can stack this farmland in the upcoming game.')
                                                    // }}
                                                    className=" duration-500 w-full  py-3 sm:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                                                    Stack now
                                                </button>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })

                }
            </div>
        </>

    )
}
export default UserLands