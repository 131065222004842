
const fetchHumanWalletBalanceRequest = () => {
    return {
        type: "CHECK_HUMAN_WALLET_BALANCE_REQUEST",
    };
};

const fetchHumanWalletBalanceSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_WALLET_BALANCE_SUCCESS",
        payload: payload,
    };
};

const fetchHumanWalletBalanceFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_WALLET_BALANCE_FAILED",
        payload: payload,
    };
};

export const fetchHumanWalletBalance = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanWalletBalanceRequest());
        try {

            const balance = await window.ethereum.request({
                method: "eth_getBalance",
                params: [account, "latest"]

            });
            let walletBalance = parseInt(balance)
            dispatch(
                fetchHumanWalletBalanceSuccess({
                    walletBalance,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanWalletBalanceFailed("Could not load balance"));
        }
    };
};