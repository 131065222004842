import React from "react";
import 'animate.css';
import Header from "../components/Header";
import { NavLink } from "react-router-dom";
import { FaCheckCircle, FaTelegramPlane } from "react-icons/fa"
import { BsTwitter } from 'react-icons/bs'
import ImageSwiper from "../components/ImageSwiper";
import StorageImageSwiper from "../components/StorageImageSwiper";

const Home = () => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (

        <div className="flex flex-col " >
            <Header data='home' />
            <main className="flex-1 ">
                <div className=" relative">
                    <img className=" absolute "
                        src={require('../Assets/Backgrounds/cloud-bg.png')} alt="cloud" />
                    <div className=" relative flex   justify-between items-center p-2 mb-2 md:mb-5 mx-5 md:mx-12 lg:mx-20 ">
                        <div className="  md:w-2/3 space-y-3 flex flex-col ">
                            <p className=" hidden md:flex text-gray-800 md:text-5xl lg:text-6xl  font-Archivo font-extrabold duration-500" >Play-to-earn <br />metaverse NFTs <br /> GameFi  </p>
                            <div className="flex items-center justify-between md:hidden">
                                <div>
                                    <p className="  text-gray-800 text-2xl sm:text-4xl font-Archivo font-extrabold duration-500" >Play-to-earn <br />metaverse NFTs <br /> GameFi  </p>
                                </div>
                                <div className=" w-2/5 md:hidden ">
                                    <img className=""
                                        src={require('../Assets/Elements/farmland.png')} alt="farmland" />
                                </div>
                            </div>

                            <p className="text-gray-600  text-sm lg:text-base font-Archivo ">
                                You can count on us.We'll be launching one of the best
                                metaverse NFTs, GameFi, that simulates actual-world scenarios.
                                This will protect your assets from inflation and rug pulling
                                while also allowing your investment to grow over time.</p>
                            <div>
                                <NavLink to="/Marketplace">
                                    <button
                                        className="px-6 sm:px-8 lg:px-12 py-2 sm:py-2 lg:py-3 text-sm sm:text-base rounded-lg bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600 duration-500">
                                        Get a land
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        <div className=" w-1/3 hidden md:flex">
                            <img className=""
                                src={require('../Assets/Elements/farmland.png')} alt="farmland" />
                        </div>
                    </div>
                    <div className=" relative p-2 md:p-5 mx-5 md:mx-10 lg:mx-16 overflow-hidden   space-y-4 rounded-t-3xl  ">
                        <div className=" space-x-2   justify-between  flex">
                            <div className=" md:w-3/5  lg:w-3/4 space-y-2">
                                <div className="flex flex-col sm:flex-row  space-x-2 justify-between" >
                                    <div className="sm:w-1/2 md:w-auto " >
                                        <p className=" text-gray-800 font-Archivo font-bold text-2xl sm:text-3xl lg:text-4xl">What is Zamiata?</p>
                                        <p className=" font-Archivo sm:text-base lg:text-lg text-gray-700 font-normal">It simulates actions seen in the real world, like farming, renting out land and apartments, building factories and offices, working as a worker and generating income , and eating to stay healthy.</p>
                                    </div>
                                    <div className="  w-1/3 hidden sm:flex md:hidden justify-center items-center ">
                                        <img className=""
                                            src={require('../Assets/Elements/farmland-all.png')} alt="farmland" />
                                    </div>
                                    <div className=" flex  sm:hidden md:hidden justify-center items-center ">
                                        <img className="w-80"
                                            src={require('../Assets/Elements/farmland-all.png')} alt="farmland" />
                                    </div>

                                </div>
                                <div className="flex flex-col lg:flex-row space-y-3 md:space-y-0 lg:space-x-2">
                                    <div className="">
                                        <p className=" font-Archivo font-bold text-xl md:text-xl lg:text-2xl text-gray-800">Mechanics</p>
                                        <p className="font-Archivo text-sm text-gray-700 font-normal">To stay energised when playing Zamiata, every player needs to eat food and drink water. Food can be obtained through crops, and cultivating land can produce crops. Water harvesting is possible on some types of land. Each player has the ability to buy or sell land. A player can still use their property to construct houses, businesses, and offices even if they don't have the time to cultivate. They can employ others to finish the job. Crops, food, water, and services can all be purchased or sold on the internal market.</p>
                                    </div>
                                    <div className=" ">
                                        <p className="font-Archivo font-bold text-xl md:text-xl lg:text-2xl text-gray-800"> Our Mission</p>
                                        <p className="font-Archivo text-sm text-gray-700 font-normal">We intend to create a fully functional ecosystem that won't require continual team input. We can totally decentralize and stabilize the play-to-earn metaverse together. We want people to enjoy living in Zamiata and to feel a connection to the environment.</p>
                                    </div>
                                </div>
                                <div className=" ">
                                    <p className="font-Archivo font-bold text-xl md:text-xl lg:text-2xl text-gray-800">Technology</p>
                                    <p className="font-Archivo text-sm text-gray-700 font-normal">We're developing a technology that will keep the network robust and decentralised in order to put the Zamiata metaverse concept into practise the way it should be. We'll be able to accomplish this using the Play-To-Earn idea.</p>
                                </div>

                            </div>
                            <div className=" w-1/2 hidden  md:flex justify-center items-center ">
                                <img className=""
                                    src={require('../Assets/Elements/farmland-all.png')} alt="farmland" />
                            </div>
                        </div>
                    </div>

                    <div className=" relative mx-0 md:mx-10 lg:mx-16 space-y-4 ">
                        <div className=" flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-4 ">
                            <div className="w-full md:w-3/5  bg-gray-800">
                                <div className=" flex space-x-2 items-center">
                                    <div className=" w-10 h-10 bg-orange-600 flex justify-center items-center">
                                        <p className=" font-Inter text-4xl font-extrabold text-blue-700">1</p>
                                    </div>
                                    <p className=" font-Archivo text-white font-bold text-sm sm:text-xl md:text-base  lg:text-xl">Select the farmland that will work best for you.</p>
                                </div>
                                <div className="px-5 py-3">

                                    <p className=" font-Archivo text-gray-300  text-sm sm:text-base">If you own <span className=" font-bold">LAND NFT</span> , you can begin farming and collecting water after the game launches. Later, it can be converted into commercial land.</p>
                                    <ImageSwiper />
                                </div>
                            </div>

                            <div className=" w-full  px-5 py-3 bg-gray-800 ">
                                <p className=" font-Archivo text-white font-bold sm:text-xl md:text-lg  lg:text-xl">A Zamiata land box is available for purchase on the Zamiata Marketplace</p>
                                <p className=" font-Archivo text-gray-300 text-sm sm:text-base">The more land you have, the more crops you can cultivate.</p>
                                <div className="py-5  items-center flex flex-col space-y-2 justify-center">
                                    <div className="w-2/4 space-y-2 text-center">
                                        <img className="   rounded-lg"
                                            src={require('../Assets/ZamBox/land-zam-box.png')} alt="land-box" />
                                        <p className="font-Inter text-xs lg:text-sm text-gray-500">A random-sized land and water source are included in each box.</p>
                                    </div>
                                    <div>
                                        <NavLink to="/Marketplace">
                                            <button
                                                className="px-6 sm:px-8 lg:px-12 py-2 sm:py-2 lg:py-3 text-sm sm:text-base rounded-lg bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600 duration-500">
                                                Purchase now
                                            </button>
                                        </NavLink>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className=" flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-4 ">
                            <div className="w-full md:w-3/5  bg-gray-800">
                                <div className=" flex space-x-2 items-center">
                                    <div className=" w-10 h-10 bg-orange-600 flex justify-center items-center">
                                        <p className=" font-Inter text-4xl font-extrabold text-blue-700">2</p>
                                    </div>
                                    <p className=" font-Archivo text-white font-bold text-sm sm:text-xl md:text-base  lg:text-xl">Select the storage building that will work best for you.</p>
                                </div>
                                <div className="px-5 py-3">
                                    <p className=" font-Archivo text-gray-300  text-sm sm:text-base">To store items, a <span className=" font-bold">STORAGE BUILDING NFT</span> is required.</p>
                                    <StorageImageSwiper />
                                </div>
                            </div>

                            <div className=" w-full  px-5 py-3 bg-gray-800 ">
                                <p className=" font-Archivo text-white font-bold sm:text-xl md:text-lg  lg:text-xl">A Zamiata storage building box is available for purchase on the Zamiata Marketplace</p>
                                {/* <p className=" font-Archivo text-gray-300 text-sm sm:text-base">The more land you have, the more crops you can cultivate.</p> */}
                                <div className="py-5  items-center flex flex-col space-y-2 justify-center">
                                    <div className="w-2/4 space-y-2 text-center">
                                        <img className="   rounded-lg"
                                            src={require('../Assets/ZamBox/building-zam-box.png')} alt="land-box" />
                                        <p className="font-Inter text-xs lg:text-sm text-gray-500">A different storage structure, such as a barn, water tank, or silo, is contained in each box.</p>
                                    </div>
                                    <div>
                                        <NavLink to="/Marketplace">
                                            <button
                                                className="px-6 sm:px-8 lg:px-12 py-2 sm:py-2 lg:py-3 text-sm sm:text-base rounded-lg bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600 duration-500">
                                                Purchase now
                                            </button>
                                        </NavLink>
                                    </div>

                                </div>


                            </div>
                        </div>
                        <div>
                            <div className=" flex flex-col ">
                                <div className=" px-5 space-y-2">
                                    <p className=" text-gray-700 font-Archivo  font-extrabold text-2xl md:text-3xl">TOKENOMICS</p>
                                    <div>
                                        <p className=" text-gray-600 font-Inter font-semibold text-base md:text-xl">Governance token</p>
                                        <div className=" items-center flex space-x-1">
                                            <NavLink to="/Tokens">
                                                <img className=" w-14 md:w-20 "
                                                    src={require('../Assets/Tokens/zam-token.png')} alt="zamiata-token" />
                                            </NavLink>
                                            <p className=" font-Inter text-sm md:text-base text-gray-400">Zamiata - ZAM</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className=" text-gray-600 font-Inter font-semibold text-base md:text-xl">In-game Tokens</p>
                                        <div className=" items-center flex space-x-2">
                                            <div className="  items-center flex flex-col justify-center">
                                                <NavLink to="/Tokens">
                                                    <img className=" w-12 md:w-16 "
                                                        src={require('../Assets/Tokens/zws-token.png')} alt="wheat-seeds-token" />
                                                </NavLink>

                                                <p className=" font-Inter text-xs md:text-sm text-center text-gray-400">ZWS <br />Wheat Seeds</p>
                                            </div>
                                            <div className="  items-center flex flex-col justify-center">
                                                <NavLink to="/Tokens">
                                                    <img className=" w-12 md:w-16 "
                                                        src={require('../Assets/Tokens/zwr-token.png')} alt="water-token" />
                                                </NavLink>

                                                <p className=" font-Inter text-xs md:text-sm text-center text-gray-400">ZWR <br />Water</p>
                                            </div>
                                            <div className="  items-center flex flex-col justify-center">
                                                <NavLink to="/Tokens">
                                                    <img className=" w-12 md:w-16"
                                                        src={require('../Assets/Tokens/zwg-token.png')} alt="wheat-grains-token" />
                                                </NavLink>

                                                <p className=" font-Inter text-xs md:text-sm text-center text-gray-400">ZWG <br />Wheat Grains</p>
                                            </div>
                                            <div className="  items-center flex flex-col justify-center">
                                                <NavLink to="/Tokens">
                                                    <img className=" w-12 md:w-16"
                                                        src={require('../Assets/Tokens/zwf-token.png')} alt="wheat-flour-token" />
                                                </NavLink>

                                                <p className=" font-Inter text-xs md:text-sm text-center text-gray-400">ZWF <br />Wheat Flour</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <img className=""
                                    src={require('../Assets/Elements/zamiata-tokenomic.jpg')} alt="tokenomic" />
                            </div>
                        </div>

                        <div>
                            <div className=" bg-gray-800 p-5 space-y-3 flex flex-col items-center justify-center">
                                <p className=" text-white font-Archivo font-bold text-4xl">ROADMAP</p>
                                <div className=" space-y-4 md:space-y-0 grid grid-cols-1 md:grid-cols-2  md:gap-4 lg:gap-6">
                                    <div className=" flex flex-col  items-center justify-center">
                                        <div className=" bg-gray-900 flex rounded-3xl overflow-hidden shadow-xl space-x-2  items-center">
                                            <div className=" items-center flex justify-center text-center px-2 lg:px-6 ">
                                                <p className=" font-Archivo font-extrabold text-blue-600 text-xl md:text-base lg:text-xl">Phase <br />1</p>
                                            </div>
                                            <div className=" p-2 md:p-2 lg:p-2 w-64 md:w-52 lg:w-64  bg-gray-200">
                                                <p className=" font-Archivo font-bold text-lg md:text-lg lg:text-xl text-orange-600">Beginning</p>
                                                <div className=" md:text-sm lg:text-base">
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-green-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Launch Farmlands</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-green-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Launch Storage Buildings</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-green-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Launch Tokenomics</p>
                                                    </div>

                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-green-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Launch Tokens</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className=" flex flex-col  items-center justify-center">
                                        <div className=" bg-gray-900 flex rounded-3xl overflow-hidden shadow-xl space-x-2  items-center">
                                            <div className=" items-center flex justify-center text-center px-2 lg:px-6 ">
                                                <p className=" font-Archivo font-extrabold text-blue-600 text-xl md:text-base lg:text-xl">Phase <br />2</p>
                                            </div>
                                            <div className=" p-2 md:p-2 lg:p-2 w-64 md:w-52 lg:w-64  bg-gray-200">
                                                <p className=" font-Archivo font-bold text-lg md:text-lg lg:text-xl text-orange-600">Game Development</p>
                                                <div className=" md:text-sm lg:text-base">
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-green-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">UI development</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Alpha Testing</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Beta Testing</p>
                                                    </div>

                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Launch on Mainnet</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className=" flex flex-col  items-center justify-center">
                                        <div className=" bg-gray-900 flex rounded-3xl overflow-hidden shadow-xl space-x-2  items-center">
                                            <div className=" items-center flex justify-center text-center px-2 lg:px-6 ">
                                                <p className=" font-Archivo font-extrabold text-blue-600 text-xl md:text-base lg:text-xl">Phase <br />3</p>
                                            </div>
                                            <div className=" p-2 md:p-2 lg:p-2 w-64 md:w-52 lg:w-64  bg-gray-200">
                                                <p className=" font-Archivo font-bold text-lg md:text-lg lg:text-xl text-orange-600">Game Launch</p>
                                                <div className=" md:text-sm lg:text-base">
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Farming</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Animals</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Flour mill</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Power plant</p>
                                                    </div>

                                                    {/* <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Employment <span className=" text-sm">{"(Play-To-Earn)"}</span></p>
                                                    </div> */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className=" flex flex-col  items-center justify-center">
                                        <div className=" bg-gray-900 flex rounded-3xl overflow-hidden shadow-xl space-x-2  items-center">
                                            <div className=" items-center flex justify-center text-center px-2 lg:px-6 ">
                                                <p className=" font-Archivo font-extrabold text-blue-600 text-xl md:text-base lg:text-xl">Phase <br />4</p>
                                            </div>
                                            <div className=" p-2 md:p-2 lg:p-2 w-64 md:w-52 lg:w-64  bg-gray-200">
                                                <p className=" font-Archivo font-bold text-lg md:text-lg lg:text-xl text-orange-600">Buildings</p>
                                                <div className=" md:text-sm lg:text-base">

                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Employment <span className=" text-xs">{"(Play-To-Earn)"}</span></p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Commercial lands</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Build Flats</p>
                                                    </div>
                                                    <div className="text-gray-600 flex space-x-1 items-center">
                                                        <div className=" text-gray-600">
                                                            <FaCheckCircle />
                                                        </div>
                                                        <p className=" font-Inter text-gray-500">Renting <span className=" text-xs">{"(Lands & Flats)"}</span></p>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className=" relative flex flex-col space-y-1   justify-center items-center p-2 mb-2 md:mb-5 mx-5 md:mx-12 lg:mx-20 ">
                        <p className=" font-Archivo font-semibold text-sm md:text-base lg:text-lg text-gray-600 text-center">If you wish to share your experience with us or have any questions, you may contact us at</p>
                        <p className=" bg-orange-600 rounded-md p-1 text-xs md:text-sm text-white font-Inter">support@zamiata.com</p>
                        <div className="mt-2 cursor-pointer space-x-5  text-gray-800 flex ">
                            <div
                                onClick={() => openInNewTab('https://twitter.com/ZamiataOfficial')}
                                className="duration-300 flex flex-col justify-center items-center text-blue-400 hover:text-blue-500">
                                <BsTwitter size={30} />
                                <p className="font-Inter text-xs font-bold text-gray-500">Twitter</p>
                            </div>
                            <div
                                onClick={() => openInNewTab('https://t.me/zamiata')}
                                className="duration-300 items-center flex flex-col justify-center   text-blue-700 hover:text-blue-800">
                                <FaTelegramPlane size={30} />
                                <p className="font-Inter text-xs font-bold text-gray-500">Telegram</p>
                            </div>


                        </div>
                    </div>
                </div>
                <footer className="mt-5 text-center bg-gray-700 flex flex-col items-center justify-center">
                    <div className="my-10 flex flex-col items-center">
                        <p className=" font-Archivo font-extrabold text-xl text-gray-800">ZAMIATA</p>
                        <p className="text-gray-800 font-Inter">Copyright © 2022</p>
                    </div>
                </footer>
            </main>

        </div>

    )
};
export default Home;