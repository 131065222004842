import React, { useState, useEffect } from "react";
import { Tab } from '@headlessui/react'
import zamBoxIcon from "../Assets/ZamBox/land-box-128.png"
import farmlandIcon from "../Assets/Lands/farmland-icon.png"
import buildingIcon from "../Assets/Storage_Buildings/building-icon-128x128.png"
import UserLands from "./UserAccount/UserLands";
import UserZlBox from "./UserAccount/UserZlBox";
import UserStorageBuildings from "./UserAccount/UserStorageBuildings";
import { useSelector } from "react-redux";
import Loading from "../components/loadingComp/Loading"
import EmpetyAsset from "./EmpetyAsset";


export default function AssetsTabs() {
    const landData = useSelector((state) => state.humanLand);
    const zlBoxData = useSelector((state) => state.humanZlBox);
    const storageBuldingData = useSelector((state) => state.humanStorageBuldings);
    //console.log(storageBuldingData.ownerStorageBuilding)
    const [totalLand, setTotalLand] = useState('')
    const [totalZlBox, setTotalZlBox] = useState('')
    //console.log(storageBuldingData.storageBuildingBalance)

    useEffect(() => {
        if (!landData.loading) {
            setTotalLand(landData.landBalance)
        }
    }, [landData.loading]);
    useEffect(() => {
        if (!zlBoxData.loading) {
            setTotalZlBox(zlBoxData.zlBoxBalance)
        }
    }, [zlBoxData.loading]);
    return (
        <Tab.Group defaultIndex={0}>
            <Tab.List className=" flex font-Archivo text-sm sm:text-base space-x-1 sm:space-x-3 border-b">
                <Tab className=" items-center font-bold space-x-2 flex flex-col sm:flex-row p-2 ui-selected:border-b-2 ui-selected:border-orange-600 ui-selected:text-orange-600 ui-not-selected:border-b-2 ui-not-selected:border-opacity-0 ui-not-selected:bg-white ui-not-selected:text-slate-400 duration-500">
                    <div className="">
                        <img className="w-10" src={zamBoxIcon} alt="zam-box" />
                    </div>
                    <div className=" flex space-x-1">
                        <span >Zam Box</span>
                        <div className=" bg-gray-200 w-6 h-6 rounded-full flex justify-center items-center ">
                            <span className=" text-xs font-Inter  text-gray-500">{totalZlBox}</span>
                        </div>
                    </div>

                </Tab>
                <Tab className=" items-center font-bold space-x-2 flex flex-col sm:flex-row p-2 ui-selected:border-b-2 ui-selected:border-orange-600 ui-selected:text-orange-600 ui-not-selected:border-b-2 ui-not-selected:border-opacity-0 ui-not-selected:bg-white ui-not-selected:text-slate-400 duration-500">
                    <div className="">
                        <img className="w-10" src={farmlandIcon} alt="land" />
                    </div>
                    <div className=" flex space-x-1">
                        <span >Lands</span>
                        <div className=" bg-gray-200 w-6 h-6 rounded-full flex justify-center items-center ">
                            <span className=" text-xs font-Inter   text-gray-500">{totalLand}</span>
                        </div>
                    </div>

                </Tab>
                <Tab className=" items-center font-bold space-x-2 flex flex-col sm:flex-row p-2 ui-selected:border-b-2 ui-selected:border-orange-600 ui-selected:text-orange-600 ui-not-selected:border-b-2 ui-not-selected:border-opacity-0 ui-not-selected:bg-white ui-not-selected:text-slate-400 duration-500">
                    <div className="">
                        <img className="w-10" src={buildingIcon} alt="building" />
                    </div>
                    <div className=" flex space-x-1">
                        <span >Buldings</span>
                        <div className=" bg-gray-200 w-6 h-6 rounded-full flex justify-center items-center ">
                            <span className=" text-xs font-Inter   text-gray-500">{storageBuldingData.storageBuildingBalance}</span>
                        </div>
                    </div>

                </Tab>
            </Tab.List>
            <Tab.Panels className="p-4">


                <Tab.Panel>
                    <div>
                        {
                            zlBoxData.loading ?
                                <Loading />
                                :
                                (
                                    totalZlBox > 0 ?
                                        <UserZlBox />
                                        : (<EmpetyAsset assetName={"zam box"} />)
                                )
                        }
                    </div>
                </Tab.Panel>

                <Tab.Panel>
                    <div >
                        {
                            landData.loading ?
                                <Loading />
                                :
                                (
                                    totalLand > 0 ?
                                        <UserLands />
                                        : (<EmpetyAsset assetName={"land"} />)
                                )
                        }
                    </div>


                </Tab.Panel>

                <Tab.Panel>
                    <div >
                        {
                            storageBuldingData.loading ?
                                <Loading />
                                :
                                (
                                    storageBuldingData.storageBuildingBalance > 0 ?
                                        <UserStorageBuildings />
                                        : (<EmpetyAsset assetName={"storage bulding"} />)
                                )
                        }
                    </div>


                </Tab.Panel>

            </Tab.Panels>
        </Tab.Group>
    );
}