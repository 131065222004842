const initialState = {
    loading: true,
    landBalance: null,
    ownerLands: [],
    error: false,
    errorMsg: "",
};

const humanLandDataReducre = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_HUMAN_LAND_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_HUMAN_LAND_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                landBalance: action.payload.landBalance,
                ownerLands: action.payload.ownerLands,
                //landOwner: action.payload.allOwnerLips,
            };
        case "CHECK_HUMAN_LAND_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default humanLandDataReducre;