import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Header";
import AssetsTab from "../../components/AssetsTabs";
import CreateAccountPopup from "../../components/Popups/CreateAccountPopup";
import MetamaskPopup from "../../components/Popups/MetamaskPopup";
import { GiMale, GiFemale } from 'react-icons/gi'
import bornIcon from "../../Assets/Icons/baby-born.png"
import dnaIcon from "../../Assets/Icons/dna.png"
import ScaltonLoadingUser from "../../components/loadingComp/ScaltonLoadingUser";
import LowBalancePopups from "../../components/Popups/LowBalancePopups";
function Account() {

    //const blockchain = useSelector((state) => state.blockchain);
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.human);
    const [born, setBorn] = useState('')
    const [humanName, setHumanName] = useState('')
    const [humanDna, setHumanDna] = useState('')
    const [openModel, setOpenModel] = useState(false)
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [refresh, setRefresh] = useState('')
    const [metamaskPopup, setMetamaskPopup] = useState(false)
    //const authKeys = 3210258213205841
    const getHuman = () => {
        setOpenModel(false)
        if (!data.loading) {

            if (data.humanData.isHuman) {
                setHumanName(data.humanData.fname.charAt(0).toUpperCase() + data.humanData.fname.slice(1) + " " + data.humanData.lname.charAt(0).toUpperCase() + data.humanData.lname.slice(1))
                setHumanDna(data.humanData.dna)
                let currentTimestamp = (data.humanData.bornTime * 1000)
                let date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: '2-digit' }).format(currentTimestamp)
                setBorn(date);
            }
        }
    }
    //let svgCode = multiavatar(humanDna)

    useEffect(() => {
        if (blockchain.errorStatus) {
            setMetamaskPopup(true)
        } else {
            getHuman()
        }

    }, [data.loading, refresh, blockchain.errorStatus]);

    return (
        <>
            {metamaskPopup && <MetamaskPopup closeMetamaskPopup={setMetamaskPopup} />}
            {openModel && <CreateAccountPopup closeModel={setOpenModel} doRefesh={setRefresh} closeLowBalancePopUp={setLoawBalancePopup} />}
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            <div className="flex flex-col h-screen " >
                <Header />
                <main className="flex-1 overflow-scroll ">
                    <div className="  mx-auto ">
                        <div className=" mt-4">
                            <div className="mx-2 md:mx-10  py-2 px-2  flex flex-col space-y-2">
                                {
                                    !data.loading ?
                                        data.humanData.isHuman ?
                                            <div className=" sm:mx-6 animate__animated animate__fadeIn flex flex-col justify-center sm:justify-start items-center sm:items-start ">

                                                <div className="   flex flex-col text-center justify-center   items-center  ">
                                                    <img className=" w-24 md:w-32" src={data.avatar} alt="avatar" />
                                                    <p className="font-Archivo font-bold text-slate-800 text-2xl md:text-3xl duration-500">{humanName}</p>
                                                </div>


                                                <div className=" flex space-x-2 items-center">
                                                    <div >
                                                        {
                                                            data.humanData.gender === "Male" ?
                                                                <div className=" p-1 bg-blue-500 rounded-full  text-white">
                                                                    <GiMale />
                                                                </div>
                                                                :
                                                                (
                                                                    <div className=" p-1 bg-pink-500 rounded-full  text-white">
                                                                        <GiFemale />
                                                                    </div>
                                                                )
                                                        }

                                                    </div>
                                                    <div className=" ">
                                                        <div className=" flex rounded-lg border-2 border-solid border-pink-400 bg-pink-400 items-center  overflow-hidden">
                                                            <div className="w-6 md:w-7  flex p-0.5  bg-white   ">
                                                                <img className="" src={bornIcon} alt="born" />
                                                            </div>
                                                            <div className=" bg-pink-400 px-1 items-center">
                                                                <span className="font-medium text-xs md:text-sm text-white ">{born}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="flex rounded-lg border-2 border-solid border-orange-400 bg-orange-400 items-center  overflow-hidden ">
                                                            <div className="w-6 md:w-7  flex p-0.5  bg-white   ">
                                                                <img className="" src={dnaIcon} alt="dna" />
                                                            </div>
                                                            <div className=" bg-orange-400 px-1 items-center">
                                                                <span className="font-medium text-xs md:text-sm text-white ">{humanDna}</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            : (
                                                <div className=" py-3 flex  flex-col justify-center  items-center ">
                                                    <div className="  text-center">
                                                        {/* <span className=" font-Archivo font-extrabold text-2xl md:text-3xl lg:text-4xl text-slate-800">Create account  <br />
                                                        <span className=" font-Inter font-bold text-slate-800 text-base md:text-xl lg:text-2xl"> and mint your <span className=" text-orange-600"> NFT Avatar for free </span></span>
                                                    </span> */}
                                                        <div>
                                                            <button
                                                                onClick={() => setOpenModel(true)}
                                                                className="w-auto mt-1 md:mt-2 lg:mt-5 px-3 md:px-4 py-1 md:py-2 lg:px-8 lg:py-3 rounded-lg bg-white border-2 border-orange-600 font-Inter font-bold text-orange-600 hover:bg-blue-600 hover:border-opacity-0 hover:text-white duration-500">
                                                                Create account
                                                            </button>
                                                        </div>
                                                    </div>

                                                </div>
                                            )

                                        : (<div><ScaltonLoadingUser /></div>)
                                }
                            </div>

                            <div className="mt-2 mx-2 md:mx-10 justify-center">
                                <AssetsTab />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Account;