import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import PresellZamTokenBuyPopup from '../Popups/PresellZamTokenBuyPopup'
import BeatLoader from "react-spinners/BeatLoader";
import LowBalancePopups from '../Popups/LowBalancePopups';
import Metamask from '../Metamask';

const ZamTokenPreSell = () => {
    const presellZamDataState = useSelector((state) => state.presellZamData);
    const blockchain = useSelector((state) => state.blockchain);
    //console.log(presellZamDataState.presellZam)
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [zamiataBuyPopup, setzamiataBuyPopup] = useState(false)
    const [tokenPrice, settokenPrice] = useState('')
    const [minAmount, setminAmount] = useState('')
    const [maxAmount, setmaxAmount] = useState('')
    const [metamaskError, setmetamaskError] = useState(false)

    async function getPresellData() {
        if (!presellZamDataState.loading) {
            settokenPrice(parseFloat(blockchain.web3.utils.fromWei(presellZamDataState.presellZam._buyPrice, 'ether')))
            setminAmount(parseFloat(blockchain.web3.utils.fromWei(presellZamDataState.presellZam._minBuy, 'ether')))
            setmaxAmount(parseFloat(blockchain.web3.utils.fromWei(presellZamDataState.presellZam._maxBuy, 'ether')))
        }
    }

    useEffect(() => {
        if (blockchain.errorStatus) {
            setmetamaskError(true)
        }
        else {
            setmetamaskError(false)
        }
    }, [blockchain.errorStatus]);

    useEffect(() => {
        getPresellData()
    }, [presellZamDataState.loading]);
    return (
        <>
            {zamiataBuyPopup && <PresellZamTokenBuyPopup buyPrice={tokenPrice} min={minAmount} max={maxAmount} closePresellPopop={setzamiataBuyPopup} closeLowBalancePopUp={setLoawBalancePopup} />}
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            <div className="relative overflow-hidden mb-4 animate__animated animate__fadeIn flex flex-row   bg-white rounded-2xl shadow">
                {metamaskError &&
                    <div className='flex bg-white  opacity-90   justify-center items-center w-full h-full absolute'>
                        <Metamask />
                    </div>
                }

                <div className="w-full flex flex-col space-y-1 md:space-y-2">
                    <div className='flex'>
                        <div className="flex flex-col sm:flex-row sm:items-center space-x-2 ">
                            <p className=" bg-red-400 px-2 py-1 text-center   rounded-br-md text-red-700 text-xs sm:text-sm md:text-base lg:text-xl font-Archivo font-bold  ">1 Million Presell</p>
                            <p className=" font-Archivo font-extrabold text-gray-700 text-sm sm:text-base  md:text-xl lg:text-2xl">Zamiata Token - ZAM</p>
                        </div>
                    </div>

                    <div className=' h-full flex flex-col pb-2 justify-between'>
                        <div className="ml-2 font-Archivo ">

                            {/* <p className=" text-xs sm:text-sm md:text-base text-gray-600"><span className=" font-bold text-gray-700">Zamiata - ZAM</span> is a governance token, and you can use it to pay all in-game fees and buy other in-game tokens.</p> */}
                            <div className=" text-xs sm:text-sm md:text-base flex items-center space-x-1  ">
                                <p className=" font-Inter font-bold text-gray-500 ">Price 100 ZAM /</p>
                                <div className=" flex items-center space-x-1  ">
                                    {
                                        !presellZamDataState.loading ?
                                            <div className='animate__animated animate__fadeIn flex items-center space-x-1'>
                                                <span className=" font-Inter   font-bold text-purple-600 ">{tokenPrice * 100} </span>

                                                <img className=" w-3"
                                                    src={polygonIcon} alt="polygon-icon" />
                                                <span className=" font-Inter   font-bold text-purple-600 ">MATIC </span>
                                            </div>

                                            : (!metamaskError && <div><BeatLoader color="#bcbcbc" size={7} /></div>)
                                    }
                                </div>
                            </div>

                        </div>
                        <div className="flex ml-2">
                            <button
                                onClick={() => {
                                    setzamiataBuyPopup(true)
                                }}
                                className=" mt-2 py-2 px-5  md:px-7  rounded-lg  bg-orange-600  text-xs sm:text-sm font-Inter font-bold text-white hover:bg-blue-600 duration-500">
                                Purchase now
                            </button>

                        </div>
                    </div>

                </div>
                <div className="p-2 w-4/12 sm:w-40 flex flex-col justify-center items-center space-y-2 ">
                    <div className=" flex  justify-center items-end">
                        <img className="  rounded-xl "
                            src={require('../../Assets/Tokens/zam-token-500x500.png')} alt="zam-token" />
                        <div className=" flex absolute p-1 ">

                        </div>
                    </div>


                </div>


            </div>
        </>
    )
}

export default ZamTokenPreSell