// log
import store from "../store";

const fetchDataRequest = () => {
    return {
        type: "CHECK_DATA_REQUEST",
    };
};

const fetchDataSuccess = (payload) => {
    return {
        type: "CHECK_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchDataFailed = (payload) => {
    return {
        type: "CHECK_DATA_FAILED",
        payload: payload,
    };
};

export const fetchLandData = () => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {
            let isDirectMint = await store
                .getState()
                .blockchain.lands.methods.isDirectMintable()
                .call();
            var landCount = 3;
            let allLands = []
            for (var i = 0; i <= landCount; i++) {
                const land = await store
                    .getState()
                    .blockchain.farmlandData.methods.landProperties(i)
                    .call();
                allLands.push(land)
            }
            dispatch(
                fetchDataSuccess({
                    allLands,
                    isDirectMint,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchDataFailed("Could not load data from contract."));
        }
    };
};