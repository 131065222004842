import React from "react";
import BarLoader from "react-spinners/BarLoader";

const ScaltonLoadingUser = () => {

    return (
        <div className="p-4 max-w-sm w-full  ">
            <div className=" ">
                <div className=" flex flex-col space-x-4">
                    <div className="">
                    </div>
                    <div className=" flex-1 mt-2  space-y-6 py-1">
                        <div className="space-y-3">
                            <BarLoader
                                color="#bcbcbc"
                                height={15}
                                width={300}
                            />
                            <BarLoader
                                color="#bcbcbc"
                                height={20}
                                width={300}
                            />
                            <BarLoader
                                color="#bcbcbc"
                                height={15}
                                width={250}
                            />
                            <BarLoader
                                color="#bcbcbc"
                                height={20}
                                width={300}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScaltonLoadingUser;