import store from "../../store";
const fetchHumanZamTokenRequest = () => {
    return {
        type: "CHECK_HUMAN_ZAMTOKEN_REQUEST",
    };
};

const fetchHumanZamTokenSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_ZAMTOKEN_SUCCESS",
        payload: payload,
    };
};

const fetchHumanZamTokenFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_ZAMTOKEN_FAILED",
        payload: payload,
    };
};

export const fetchHumanZamToken = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanZamTokenRequest());
        try {

            let zamTokenBalance = await store
                .getState()
                .blockchain.zamTokenContract.methods.balanceOf(account)
                .call();
            dispatch(
                fetchHumanZamTokenSuccess({
                    zamTokenBalance
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanZamTokenFailed("Could not load balance"));
        }
    };
};