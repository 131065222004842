import React from "react";
import { NavLink } from "react-router-dom";

const EmpetyAsset = ({ assetName }) => {

    return (
        <div className="animate__animated animate__fadeIn text-center flex justify-center mt-5 ">
            <div className=" text-lg font-Inter text-gray-400">
                <p>You haven't owned any {assetName}. </p>
                <p>You should have one.</p>
                <div>
                    <NavLink to="/Marketplace">
                        <button
                            className="w-auto mt-1  px-3  py-1 rounded-lg bg-white border-2 border-orange-600 font-Inter font-bold text-orange-600 hover:bg-blue-600 hover:border-opacity-0 hover:text-white duration-500">
                            Get a {assetName}
                        </button>
                    </NavLink>

                </div>
            </div>
        </div>
    )
}
export default EmpetyAsset;