// log
import store from "../store";

const fetchStorageDataRequest = () => {
    return {
        type: "CHECK_STORAGE_DATA_REQUEST",
    };
};

const fetchStorageDataSuccess = (payload) => {
    return {
        type: "CHECK_STORAGE_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchStorageDataFailed = (payload) => {
    return {
        type: "CHECK_STORAGE_DATA_FAILED",
        payload: payload,
    };
};

export const fetchStorageData = () => {
    return async (dispatch) => {
        dispatch(fetchStorageDataRequest());
        try {
            let barnData = await store
                .getState()
                .blockchain.barnContract.methods.getDetails()
                .call();

            let barnStorageData = await store
                .getState()
                .blockchain.barnDataContract.methods.properties(0)
                .call();
            //console.log(barnStorageData)
            let waterTankData = await store
                .getState()
                .blockchain.waterTankContract.methods.getDetails()
                .call();
            let waterTankStorage = await store
                .getState()
                .blockchain.waterTankDataContract.methods.properties(0)
                .call();

            let siloData = await store
                .getState()
                .blockchain.siloContract.methods.getDetails()
                .call();
            let siloStorage = await store
                .getState()
                .blockchain.siloDataContract.methods.properties(0)
                .call();
            //console.log(siloData)
            dispatch(
                fetchStorageDataSuccess({
                    barnData,
                    barnStorageData,
                    waterTankData,
                    waterTankStorage,
                    siloStorage,
                    siloData,
                    //boxController,
                    //allOwnerLips,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchStorageDataFailed("Could not load box data from contract."));
        }
    };
};