import store from "../store";
import { createAvatar } from '@dicebear/avatars';
import * as malestyle from '@dicebear/avatars-male-sprites';
import * as femalestyle from '@dicebear/avatars-female-sprites';

const fetchHumanDataRequest = () => {
    return {
        type: "CHECK_HUMAN_DATA_REQUEST",
    };
};

const fetchHumanDataSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchHumanDataFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_DATA_FAILED",
        payload: payload,
    };
};

export const fetchHumanData = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanDataRequest());
        try {
            let humanData = await store
                .getState()
                .blockchain.userAccount.methods.humansData(account)
                .call();
            let avatar;
            if (humanData.isHuman) {
                if (humanData.gender === "Male") {
                    let svg = createAvatar(malestyle, {
                        dataUri: true,
                        seed: humanData.dna,

                    });
                    avatar = svg
                } else {
                    let svg = createAvatar(femalestyle, {
                        dataUri: true,
                        seed: humanData.dna,

                    });
                    avatar = svg
                }
            }
            //console.log("human data", humanData)
            dispatch(
                fetchHumanDataSuccess({
                    humanData,
                    avatar,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanDataFailed("Could not load data from contract."));
        }
    };
};