import React, { useEffect, useState } from "react";
import 'animate.css';
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import zamBoxIcon from "../../Assets/ZamBox/land-box-128.png"
import buildingIcon from "../../Assets/Storage_Buildings/building-icon-128x128.png"
import farmlandIcon from "../../Assets/Lands/farmland-icon.png"
import zamTokenIcon from "../../Assets/Tokens/zam-token-500x500.png"
import { useSelector } from "react-redux";

const ShowBalancePopup = () => {
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const landData = useSelector((state) => state.humanLand);
    const zlBoxData = useSelector((state) => state.humanZlBox);
    const zamToken = useSelector((state) => state.humanZamTokenData);
    //console.log(zamToken.zamTokenBalance)
    const storageBuldingData = useSelector((state) => state.humanStorageBuldings);
    const [totalLand, setTotalLand] = useState('')
    const [totalZlBox, setTotalZlBox] = useState('')
    const [balance, setBalance] = useState("")
    const [zamTokenBalance, setzamTokenBalance] = useState('')

    useEffect(() => {
        if (!landData.loading) {
            setTotalLand(landData.landBalance)
        }
    }, [landData.loading]);
    useEffect(() => {
        if (!zlBoxData.loading) {
            setTotalZlBox(zlBoxData.zlBoxBalance)
        }
    }, [zlBoxData.loading]);
    useEffect(() => {
        if (!zamToken.loading) {
            setzamTokenBalance(blockchain.web3.utils.fromWei(zamToken.zamTokenBalance, 'ether'))
        }
    }, [zamToken.loading]);

    useEffect(() => {
        if (!walletBal.loading) {
            if (blockchain.account !== null) {
                const amount = (blockchain.web3.utils.fromWei(String(walletBal.walletBalance), 'ether'));
                setBalance(parseFloat(amount).toFixed(3))
            }

        }
    }, [walletBal.loading]);
    return (
        <div className="animate__animated animate__fadeIn  z-10 absolute inset-0  flex justify-end items-start mt-20">
            <div className=' space-y-2 bg-gray-200 w-56 rounded-xl shadow-xl p-2 mr-14'>
                <div className=" flex space-x-2 items-center">
                    <div>
                        <img className=" w-10"
                            src={polygonIcon} alt="polygon-icon" />
                    </div>
                    <div>
                        <p className=" text-sm text-gray-500 font-Inter">Balance</p>
                        <p className=" text-xl text-gray-700 font-Inter font-extrabold">{balance} Matic</p>
                    </div>

                </div>
                <div>
                    <p className=" font-Archivo text-lg font-bold text-gray-600">Tokens</p>
                    <div className="font-bold flex space-x-2 items-center">
                        <img className=" w-7"
                            src={zamTokenIcon} alt="zam-box-icon" />
                        <div className="w-full flex space-x-1 justify-between  items-center">
                            <p className=" text-sm text-gray-700 font-Inter">ZAM</p>
                            <p className=" text-lg  scale-75 text-gray-500 font-Inter font-thin">{zamTokenBalance}</p>

                        </div>

                    </div>
                </div>
                <div>
                    <p className=" font-Archivo text-lg font-bold text-gray-600">NFTs</p>
                    <div className="font-bold flex space-x-2 items-center">
                        <img className=" w-10"
                            src={zamBoxIcon} alt="zam-box-icon" />
                        <div className="w-full flex space-x-1 justify-between items-center">
                            <p className=" text-sm text-gray-700 font-Inter">Zam Box</p>
                            <div className=" flex justify-center items-center w-5 h-5 rounded-full bg-orange-600">
                                <p className=" text-sm  scale-75 text-white font-Inter">{totalZlBox}</p>
                            </div>
                        </div>

                    </div>
                    <div className="font-bold flex space-x-2 items-center">
                        <img className=" w-10"
                            src={farmlandIcon} alt="land-icon" />
                        <div className="w-full flex space-x-1 justify-between items-center">
                            <p className=" text-sm text-gray-700 font-Inter">Lands</p>
                            <div className="flex justify-center items-cente w-5 h-5 rounded-full bg-orange-600">
                                <span className=" text-sm  scale-75  text-white font-Inter">{totalLand}</span>
                            </div>
                        </div>

                    </div>

                    <div className="font-bold flex space-x-2 items-center">
                        <img className=" w-10"
                            src={buildingIcon} alt="building-icon" />
                        <div className="w-full flex space-x-1 justify-between items-center">
                            <p className=" text-sm text-gray-700 font-Inter">Buldings</p>
                            <div className="flex justify-center items-cente w-5 h-5 rounded-full bg-orange-600">
                                <span className=" text-sm  scale-75  text-white font-Inter">{storageBuldingData.storageBuildingBalance}</span>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}
export default ShowBalancePopup;