import React, { useState } from "react";

import { BiTransfer } from "react-icons/bi"

const AboutToken = ({ tokenName, tokenSymble, tokenDescription, tokenPair }) => {
    const [usdtAmount, setUsdtAmount] = useState('')

    return (
        <div className=" space-y-2  flex flex-col justify-center  items-center ">
            <div className="bg-white p-2 rounded-lg font-Archivo sm:text-lg">
                <p className=" text-gray-600"><span className=" font-bold text-gray-700">{tokenName} - {tokenSymble}</span> {tokenDescription}</p>
            </div>
            <div className=" space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
                <div className=" bg-white p-2 rounded-lg shadow   flex flex-col ">
                    <p className=" font-Archivo font-semibold text-gray-500 text-sm sm:text-base">Amount</p>
                    <div className="  flex space-x-2 items-center font-Archivo text-gray-700">
                        <div className="bg-gray-200 rounded-md py-1 pr-2 overflow-hidden flex items-centers justify-center">
                            <input disabled className="bg-gray-200 w-full  focus:outline-none   px-2 "
                                value={usdtAmount}
                                onChange={event =>
                                    setUsdtAmount(event.target.value)}
                            />
                            <p className=" pointer-events-none  text-gray-400">{tokenPair}</p>
                        </div>
                        <BiTransfer className=" text-blue-500" size={35} />
                        <div className="bg-gray-200 rounded-md py-1 pr-2 overflow-hidden flex items-centers justify-center">
                            <input disabled className="bg-gray-200 w-full  focus:outline-none   px-2 "
                                value={usdtAmount}
                                onChange={event =>
                                    setUsdtAmount(event.target.value)}
                            />
                            <p className="pointer-events-none  text-gray-400">{tokenSymble}</p>
                        </div>

                    </div>
                    <button disabled className="mt-2 font-Archivo font-semibold rounded-md text-white py-1 bg-green-600 duration-500">Buy {tokenName}</button>

                </div>
                <div className=" bg-white p-2 rounded-lg shadow   flex flex-col ">
                    <p className=" font-Archivo font-semibold text-gray-500 text-sm sm:text-base">Amount</p>
                    <div className="  flex space-x-2 items-center font-Archivo text-gray-700">
                        <div className="bg-gray-200 rounded-md py-1 pr-2 overflow-hidden flex items-centers justify-center">
                            <input disabled className="bg-gray-200 w-full  focus:outline-none   px-2 "
                                value={usdtAmount}
                                onChange={event =>
                                    setUsdtAmount(event.target.value)}
                            />
                            <p className="pointer-events-none  text-gray-400">{tokenSymble}</p>
                        </div>
                        <BiTransfer className=" text-blue-500" size={35} />
                        <div className="bg-gray-200 rounded-md py-1 pr-2 overflow-hidden flex items-centers justify-center">
                            <input disabled className="bg-gray-200 w-full  focus:outline-none   px-2 "
                                value={usdtAmount}
                                onChange={event =>
                                    setUsdtAmount(event.target.value)}
                            />
                            <p className="pointer-events-none  text-gray-400">{tokenPair}</p>
                        </div>

                    </div>
                    <button disabled className="mt-2 font-Archivo font-semibold rounded-md text-white py-1 bg-red-600  duration-500">Sell {tokenName}</button>

                </div>
            </div>

        </div>

    )


}
export default AboutToken;