import React from "react";
import { useSelector } from "react-redux";

const ConfirmationPopupBox = ({ bd, bn }) => {
    const blockchain = useSelector((state) => state.blockchain);
    return (
        <div className=" bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className='bg-white w-full max-w-sm rounded-xl shadow-xl p-2 mx-2'>
                <div className="flex  justify-center px-2 mt-10">
                    <div className="flex justify-center items-center">
                        <div className="absolute z-0  w-32 h-32 
                        
                        rounded-full animate-spin border-4 border-solid border-t-orange-600 border-r-orange-600 border-b-orange-600">
                        </div>

                        <div className="rounded-full flex inset-0  z-10">
                            <img className=" w-28 m-2 rounded-full "
                                src={bd._imageUri} alt="zam-box" />
                        </div>
                    </div>

                </div>
                <div className="flex  text-center justify-center px-8 mt-8">
                    <span className=" font-Archivo text-3xl font-extrabold text-gray-800">Wating for <br /> confirmation</span>

                </div>
                <div className="px-8 mt-4 mb-8 text-center text-lg">
                    <span className=" font-Inter text-gray-800" >You are purchasing<br /> <span className=" text-orange-600 font-bold">{bn}</span> for  <br /> <span className=" font-bold text-gray-800">{blockchain.web3.utils.fromWei(bd._zamBoxPrice, 'ether')} MATIC</span></span>
                </div>

            </div>
        </div>
    )
}
export default ConfirmationPopupBox;