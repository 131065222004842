import React, { useState } from "react";
import { useSelector } from "react-redux";
import polygonIcon from "../../Assets/Icons/polygon-token.png"
import BuildingDetailsPopup from "../Popups/BuildingDetailsPopup";
import ConfirmationPopup from "../Popups/ConfirmationPopup";
import LowBalancePopups from "../Popups/LowBalancePopups";
import DirectFarmlandMintingPopup from "../Popups/DirectFarmlandMintingPopup";


import Tost from "../Tost";
const GetStorageBuildig = () => {
    const blockchain = useSelector((state) => state.blockchain);
    const storageBuildings = useSelector((state) => state.storageBuildingsData);
    const walletBal = useSelector((state) => state.walletBalance);
    //console.log(storageBuildings.siloData)
    const [buildingDetailsPopop, setBuildingDetailsPopuo] = useState(false)
    const [confirmPupup, setconfirmPupup] = useState(false)
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [directMintPopus, setDirectMintPopup] = useState(false)
    const [asset, setAsset] = useState('')
    const [selectedBuildingDetails, setSelectedBuildingDetails] = useState('')

    async function buyBuilding(contract, mintStatus, id, price) {
        if (mintStatus) {
            const gasPrice = await blockchain.web3.eth.getGasPrice()
            const buildingPriceFromWei = (blockchain.web3.utils.fromWei(price, 'ether'))
            const gasPriceFromWei = (blockchain.web3.utils.fromWei(gasPrice, 'ether'))
            const totalAmount = (parseFloat(buildingPriceFromWei) + parseFloat(gasPriceFromWei))
            const totalAmountToWei = (blockchain.web3.utils.toWei(totalAmount.toString(), 'ether'))
            if (walletBal.walletBalance >= totalAmountToWei) {
                setconfirmPupup(true)
                try {
                    await contract.methods.mint(blockchain.account, id)
                        .send({
                            gasPrice: gasPrice,
                            from: blockchain.account,
                            value: price,
                        })
                    Tost("success", "Transaction successful")
                    //dispatch(fetchHumanLandData(blockchain.account))
                    //setLandDetailsPopuo(false)
                } catch (error) {
                    if (error.code === 4001) {
                        Tost("error", "Denied transaction signature.")
                    } else {
                        Tost("error", error.message)
                    }
                }
                setconfirmPupup(false)
            } else {
                setLoawBalancePopup(true)
            }
        } else {
            setDirectMintPopup(true)
        }


    }
    return (
        <>
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            {confirmPupup && <ConfirmationPopup ld={selectedBuildingDetails} a={asset} />}
            {buildingDetailsPopop && <BuildingDetailsPopup mintFn={buyBuilding} closeDetailsPopop={setBuildingDetailsPopuo} bd={selectedBuildingDetails} a={asset} />}
            {directMintPopus && <DirectFarmlandMintingPopup closeDirectMintPopup={setDirectMintPopup} a={asset} />}

            <div className=" w-auto    flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                <div className="relative flex  overflow-hidden">
                    <img className=""
                        src={require('../../Assets/Storage_Buildings/barn.png')} alt="barn" />
                    <div className=" opacity-0 bg-white  hover:opacity-90 duration-500 flex justify-center items-center w-full h-full absolute">
                        <button
                            onClick={() => {
                                setAsset("Barn")
                                setSelectedBuildingDetails(storageBuildings.barnStorageData)
                                setBuildingDetailsPopuo(true)
                            }}
                            className=" duration-300 bg-orange-600 font-Inter font-semibold text-white hover:bg-blue-600  rounded-md py-1 px-2">Details</button>
                    </div>

                </div>
                <div className=" bg-white  items-center flex space-x-2 justify-center  ">
                    <p className=" font-Inter font-bold text-gray-500 text-base md:text-sm">Price</p>
                    <div className=" flex items-center space-x-1  ">
                        {
                            !storageBuildings.loading &&
                            <span className="  text-lg md:text-base font-bold text-purple-600 ">{blockchain.web3.utils.fromWei(storageBuildings.barnStorageData.price, 'ether')} </span>

                        }
                        <img className=" w-3"
                            src={polygonIcon} alt="polygon-icon" />
                    </div>
                </div>
                <div className=" bg-white ">
                    {/* <p className=" font-Inter font-bold text-lg text-center text-gray-700"> <span className=" text-sm text-gray-600">{item.landSize} Acres of</span> farmland </p> */}
                    <p className=" font-Inter font-bold text-xl sm:text-lg md:text-base text-center text-gray-700"> Barn </p>

                    <button
                        onClick={() => {
                            //setSelectedBuildingDetails(storageBuildings.barnStorageData)
                            //setAssetImg(SFLimg);
                            setAsset("barn")
                            setSelectedBuildingDetails(storageBuildings.barnStorageData)
                            buyBuilding(blockchain.barnContract, storageBuildings.barnData._isDirectMintable, storageBuildings.barnStorageData.id, storageBuildings.barnStorageData.price)
                        }}
                        className=" duration-500 w-full  py-3 sm:py-3 md:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                        Mint now
                    </button>
                </div>
            </div>

            <div className=" w-auto    flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                <div className="relative flex  overflow-hidden">
                    <img className=""
                        src={require('../../Assets/Storage_Buildings/water-tank.png')} alt="water-tank" />
                    <div className=" opacity-0 bg-white  hover:opacity-90 duration-500 flex justify-center items-center w-full h-full absolute">
                        <button
                            onClick={() => {
                                setAsset("Water Tank")
                                setSelectedBuildingDetails(storageBuildings.waterTankStorage)
                                setBuildingDetailsPopuo(true)
                            }}
                            className=" duration-300 bg-orange-600 font-Inter font-semibold text-white hover:bg-blue-600  rounded-md py-1 px-2">Details</button>
                    </div>

                </div>
                <div className=" bg-white  items-center flex space-x-2 justify-center  ">
                    <p className=" font-Inter font-bold text-gray-500 text-base md:text-sm">Price</p>
                    <div className=" flex items-center space-x-1  ">
                        {
                            !storageBuildings.loading &&
                            <span className="  text-lg md:text-base font-bold text-purple-600 ">{blockchain.web3.utils.fromWei(storageBuildings.waterTankStorage.price, 'ether')} </span>

                        }
                        <img className=" w-3"
                            src={polygonIcon} alt="polygon-icon" />
                    </div>
                </div>
                <div className=" bg-white ">
                    {/* <p className=" font-Inter font-bold text-lg text-center text-gray-700"> <span className=" text-sm text-gray-600">{item.landSize} Acres of</span> farmland </p> */}
                    <p className=" font-Inter font-bold text-xl sm:text-lg md:text-base text-center text-gray-700"> Water Tank </p>

                    <button
                        onClick={() => {
                            setAsset("waterTank")
                            setSelectedBuildingDetails(storageBuildings.waterTankStorage)
                            buyBuilding(blockchain.waterTankContract, storageBuildings.waterTankData._isDirectMintable, storageBuildings.waterTankStorage.id, storageBuildings.waterTankStorage.price)
                        }}
                        className=" duration-500 w-full  py-3 sm:py-3 md:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                        Mint now
                    </button>
                </div>
            </div>

            <div className=" w-auto    flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                <div className="relative flex  overflow-hidden">
                    <img className=""
                        src={require('../../Assets/Storage_Buildings/silo.png')} alt="silo" />
                    <div className=" opacity-0 bg-white  hover:opacity-90 duration-500 flex justify-center items-center w-full h-full absolute">
                        <button
                            onClick={() => {

                                setAsset("Silo")
                                setSelectedBuildingDetails(storageBuildings.siloStorage)
                                setBuildingDetailsPopuo(true)
                            }}
                            className=" duration-300 bg-orange-600 font-Inter font-semibold text-white hover:bg-blue-600  rounded-md py-1 px-2">Details</button>
                    </div>

                </div>
                <div className=" bg-white  items-center flex space-x-2 justify-center  ">
                    <p className=" font-Inter font-bold text-gray-500 text-base md:text-sm">Price</p>
                    <div className=" flex items-center space-x-1  ">
                        {
                            !storageBuildings.loading &&
                            <span className="  text-lg md:text-base font-bold text-purple-600 ">{blockchain.web3.utils.fromWei(storageBuildings.siloStorage.price, 'ether')} </span>

                        }
                        <img className=" w-3"
                            src={polygonIcon} alt="polygon-icon" />
                    </div>
                </div>
                <div className=" bg-white ">
                    <p className=" font-Inter font-bold text-xl sm:text-lg md:text-base text-center text-gray-700"> Silo </p>

                    <button
                        onClick={() => {
                            setAsset("silo")
                            setSelectedBuildingDetails(storageBuildings.siloStorage)
                            buyBuilding(blockchain.siloContract, storageBuildings.siloData._isDirectMintable, storageBuildings.siloStorage.id, storageBuildings.siloStorage.price)
                        }}
                        className=" duration-500 w-full  py-3 sm:py-3 md:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                        Mint now
                    </button>
                </div>
            </div>
        </>
    )

}
export default GetStorageBuildig