import React, { useEffect, useState } from "react";
import Metamask from "./Metamask";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuLogo from '../Assets/Logos/windmill.png'
import profilePickIcon from "../Assets/Icons/user.png"
import polygonIcon from "../Assets//Icons/polygon-token.png"
import zamBoxIcon from "../Assets/ZamBox/land-box-128.png"
import farmlandIcon from "../Assets/Lands/farmland-icon.png"
import buildingIcon from "../Assets/Storage_Buildings/building-icon-128x128.png"
import zamTokenIcon from "../Assets/Tokens/zam-token-500x500.png"

import CommingSoonPopup from "./Popups/CommingSoonPopup";

import { AiFillShop } from "react-icons/ai"
import { FaTelegramPlane, FaGamepad } from "react-icons/fa"
import { BsTwitter } from 'react-icons/bs'
import { RiBitCoinFill } from 'react-icons/ri'


const Header = (props) => {
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const landData = useSelector((state) => state.humanLand);
    const zlBoxData = useSelector((state) => state.humanZlBox);
    const zamToken = useSelector((state) => state.humanZamTokenData);
    const data = useSelector((state) => state.human);
    const storageBuldingData = useSelector((state) => state.humanStorageBuldings);
    const [totalLand, setTotalLand] = useState('')
    const [totalZlBox, setTotalZlBox] = useState('')
    const [zamTokenBalance, setzamTokenBalance] = useState('')
    const [balance, setBalance] = useState("")
    const [color, setColor] = useState('bg-aquamarine-blue-500 border-opacity-0')
    const [opacity, setOpacity] = useState('opacity-0')
    const [isOpen, setIsOpen] = useState(false);
    const [menu, setMenu] = useState('hidden');
    const [headerHight, setHeaderHight] = useState('')
    const genericHamburgerLine = `h-0.5 w-7 my-0.5 bg-black transition ease transform duration-300`;
    const [comingSoonPopup, setCommingSoonPopup] = useState(false)
    const [commingSoonMsg, setcommingSoonMsg] = useState('')
    const controlHeader = () => {

        if (window.scrollY > 100) {
            setColor('bg-white border-opacity-100')

            //setBorder('border-b-2')
        } else {
            setColor('bg-aquamarine-blue-500 border-opacity-0')

            //setBorder('')
        }
        if (window.scrollY > 130) {
            //setHideLogo('flex')
            setOpacity('opacity-100')
        } else {
            //setHideLogo('hidden')
            setOpacity('opacity-0')
        }


    }

    useEffect(() => {
        if (!landData.loading) {
            setTotalLand(landData.landBalance)
        }
    }, [landData.loading]);
    useEffect(() => {
        if (!zlBoxData.loading) {
            setTotalZlBox(zlBoxData.zlBoxBalance)
        }
    }, [zlBoxData.loading]);

    useEffect(() => {
        if (!zamToken.loading) {
            setzamTokenBalance(blockchain.web3.utils.fromWei(zamToken.zamTokenBalance, 'ether'))
        }
    }, [zamToken.loading]);

    useEffect(() => {
        if (!walletBal.loading) {
            if (blockchain.account !== null) {
                const amount = (blockchain.web3.utils.fromWei(String(walletBal.walletBalance), 'ether'));
                setBalance(parseFloat(amount).toFixed(3))
            }

        }
    }, [walletBal.loading]);

    useEffect(() => {
        if (props.data === 'home') {
            window.addEventListener('scroll', controlHeader)
            return () => {
                window.removeEventListener('scroll', controlHeader)
            }
        } else {
            setColor('bg-white border-opacity-100')
            setOpacity('opacity-100')
        }
    }, [])

    function showMenu() {
        if (menu === 'flex') {
            setMenu("hidden")
            //setHideLogo('flex')
            setHeaderHight('')

        } else {
            setMenu("flex")
            //setHideLogo('hidden')
            setHeaderHight('h-screen')
        }
    }

    return (
        <>
            {comingSoonPopup && <CommingSoonPopup msg={commingSoonMsg} closeCommingSoonPopup={setCommingSoonPopup} />}

            <header className={` w-full ${headerHight} md:h-auto ${color} border-b border-gray-200    duration-300   py-4 px-6 md:px-10 sticky top-0 z-50 `}>
                <div className="items-center flex justify-between">
                    <div className="flex w-full" >
                        <button className="flex flex-col h-8 w-8  justify-center items-center group"
                            onClick={() => { setIsOpen(!isOpen); showMenu() }}
                        >
                            <div
                                className={`${genericHamburgerLine} ${isOpen
                                    ? "rotate-45 translate-y-1 opacity-100  "
                                    : "opacity-100 "
                                    }`}
                            />

                            <div
                                className={`${genericHamburgerLine} ${isOpen
                                    ? "-rotate-45 -translate-y-0.5 opacity-100 "
                                    : "opacity-100 "
                                    }`}
                            />
                        </button>
                        <div className={`${menu} items-center font-Inter font-bold text-base text-gray-700`} id="navbar-defaul">
                            <ul className="w-full md:flex md:space-x-3 px-6 md:w-auto items-center top-0 left-0 md:left-auto absolute md:relative translate translate-y-12 md:translate-y-0 py-2 md:py-0  md:px-0   md:ml-5  ">
                                <li className=" md:hidden space-y-2  px-6">
                                    <div className=" flex ">
                                        <Metamask />
                                    </div>
                                    {
                                        blockchain.account !== null ?
                                            <div className=" space-y-2">
                                                <div className=" flex space-x-2 items-center">
                                                    <div>
                                                        <img className="w-10"
                                                            src={polygonIcon} alt="polygon-icon" />
                                                    </div>
                                                    <div>
                                                        <p className=" text-sm text-gray-500 font-Inter">Balance</p>
                                                        <p className=" text-base  text-gray-700 font-Inter font-extrabold">{balance} Matic</p>
                                                    </div>

                                                </div>
                                                <div>
                                                    <p className=" font-Archivo text-lg font-bold text-gray-600">Tokens</p>
                                                    <div className="font-bold flex space-x-2 items-center">
                                                        <img className=" w-7"
                                                            src={zamTokenIcon} alt="zam-box-icon" />
                                                        <div className="w-full flex space-x-1 justify-between  items-center">
                                                            <p className=" text-sm text-gray-700 font-Inter">ZAM</p>
                                                            <p className=" text-lg  scale-75 text-gray-500 font-Inter font-thin">{zamTokenBalance}</p>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <p className=" font-Archivo text-lg font-bold text-gray-600">NFTs</p>
                                                    <div className=" flex justify-between space-x-1">
                                                        <div className=" flex flex-col justify-center items-center">
                                                            <img className=" w-10"
                                                                src={zamBoxIcon} alt="zam-box-icon" />
                                                            <div className="w-full flex space-x-1 justify-between items-center">
                                                                <p className=" text-sm text-gray-700 font-Inter">Zam Box</p>
                                                                <div className=" flex justify-center items-center w-5 h-5 rounded-full bg-orange-600">
                                                                    <p className=" text-sm  scale-75 text-white font-Inter">{totalZlBox}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="flex flex-col justify-center items-center">
                                                            <img className=" w-10"
                                                                src={farmlandIcon} alt="land-icon" />
                                                            <div className="w-full flex space-x-1 justify-between items-center">
                                                                <p className=" text-sm text-gray-700 font-Inter">Lands</p>
                                                                <div className="flex justify-center items-cente w-5 h-5 rounded-full bg-orange-600">
                                                                    <p className=" text-sm  scale-75 text-white font-Inter">{totalLand}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className=" flex flex-col justify-center items-center">
                                                            <img className=" w-10"
                                                                src={buildingIcon} alt="building-icon" />
                                                            <div className="w-full flex space-x-1 justify-between items-center">
                                                                <p className=" text-sm text-gray-700 font-Inter">Buldings</p>
                                                                <div className="flex justify-center items-cente w-5 h-5 rounded-full bg-orange-600">
                                                                    <p className=" text-sm  scale-75 text-white font-Inter">{storageBuldingData.storageBuildingBalance}</p>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>

                                            : (null)
                                    }

                                </li>

                                <li className=" text-xl md:text-base p-2 md:p-0" >
                                    <NavLink to="/Marketplace"
                                        className={({ isActive }) =>
                                            [

                                                isActive ? " flex w-full md:w-auto bg-gray-800 rounded-lg py-1 md:py-1 px-3  text-white" : "flex md:w-auto py-1 md:py-1 px-3 rounded-lg bg-gray-200 hover:text-gray-900"
                                            ]
                                        } >
                                        <div className=" flex space-x-1 items-center">
                                            <AiFillShop />
                                            <p>Marketplace</p>
                                        </div>

                                    </NavLink>
                                </li>
                                <li className=" text-xl md:text-base p-2 md:p-0" >
                                    <NavLink to="/Tokens"
                                        className={({ isActive }) =>
                                            [

                                                isActive ? " flex w-full md:w-auto bg-gray-800 rounded-lg py-1 md:py-1 px-3  text-white" : "flex md:w-auto py-1 md:py-1 px-3 rounded-lg bg-gray-200 hover:text-gray-900"
                                            ]
                                        } >
                                        <div className=" flex space-x-1 items-center">
                                            <RiBitCoinFill />
                                            <p>Tokens</p>
                                        </div>

                                    </NavLink>
                                </li>
                                <li className="md:hidden">
                                    <div className=" relative flex flex-col space-y-1   justify-center items-center ">
                                        <p className=" font-Archivo font-semibold text-sm md:text-base lg:text-lg text-gray-600 text-center">If you wish to share your experience with us or have any questions, you may contact us at</p>
                                        <p className=" bg-orange-600 rounded-md p-1 text-xs md:text-sm text-white font-Inter">support@zamiata.com</p>
                                        <div className="mt-2 cursor-pointer space-x-5  text-gray-800 flex ">

                                            <a target="_blank" href="https://twitter.com/ZamiataOfficial" rel="noopener noreferrer"

                                                className="duration-300 flex flex-col justify-center items-center text-blue-400 hover:text-blue-500">
                                                <BsTwitter size={30} />
                                                <p className="font-Inter text-xs font-bold text-gray-500">Twitter</p>
                                            </a>
                                            <a target="_blank" href="https://t.me/zamiata" rel="noopener noreferrer"

                                                className="duration-300 items-center flex flex-col justify-center   text-blue-700 hover:text-blue-800">
                                                <FaTelegramPlane size={30} />
                                                <p className="font-Inter text-xs font-bold text-gray-500">Telegram</p>
                                            </a>


                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <div className={`${opacity} duration-500  w-20 md:cursor-pointer `}>
                        <NavLink to="/">
                            <img src={MenuLogo} alt="logo" className={``} />
                        </NavLink>
                    </div>
                    <div className="  flex w-full justify-end">
                        <div className="">
                            <div className=" flex  justify-end space-x-4 items-center">
                                <div className=" hidden md:flex ">
                                    <Metamask />
                                </div>
                                <a href="https://play.zamiata.com/" target="_blank" rel="noopener noreferrer">
                                    <div
                                        className="cursor-pointer w-8 h-8 flex justify-center items-center border-2 text-gray-800 hover:text-white hover:bg-gray-800 border-gray-800 hover:border-opacity-0 rounded-full p-1 duration-500">
                                        <FaGamepad size={25} />
                                    </div>
                                </a>


                                <NavLink to="/user/Account">
                                    <div className="w-8 h-8 items-center rounded-full bg-gray-800 p-1 overflow-hidden ">
                                        {
                                            data.avatar !== '' && data.avatar !== null && data.avatar !== undefined ?
                                                <img className="" src={data.avatar} alt="avatar" />
                                                : (
                                                    <img className="" src={profilePickIcon} alt="avatar" />
                                                )
                                        }

                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>




    )
}
export default Header;