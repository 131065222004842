import React from "react";
import { AiFillCloseCircle } from 'react-icons/ai'
import handSpeaker from "../../Assets/Elements/hand-speaker.png"
import telegramIcon from "../../Assets/Icons/telegram.png"
import twitterIcon from "../../Assets/Icons/twitter.png"
const CommingSoonPopup = ({ msg, closeCommingSoonPopup }) => {
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div className="  bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className=' bg-gray-900  overflow-hidden max-w-sm sm:max-w-lg rounded-xl shadow-xl p-3 mx-2'>
                <div className=" flex justify-end">
                    <button
                        onClick={() => {
                            closeCommingSoonPopup(false)

                        }}
                        className="cursor-pointer text-gray-300 hover:text-gray-500  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>
                <div className=" px-2 sm:px-10">

                    <div className=" flex space-x-5">
                        <div>
                            <img className=" w-52 sm:w-auto"
                                src={handSpeaker} alt="hand-speaker" />
                        </div>
                        <div>
                            <p className=" font-Archivo font-extrabold text-3xl sm:text-4xl text-white">Comming</p>
                            <p className=" font-Archivo font-extrabold text-3xl sm:text-4xl text-white">soon</p>
                            <p className=" font-Inter text-sm sm:text-base text-gray-400">{msg}</p>

                        </div>

                    </div>
                    <div className="flex flex-col justify-center items-center text-center">
                        <p className="font-Inter text-sm text-gray-200">Keep checking our social media outlets for updates.</p>
                        <div className="mt-2 cursor-pointer space-x-5  text-gray-800 flex ">
                            <div
                                onClick={() => openInNewTab('https://twitter.com/ZamiataOfficial')}
                                className="duration-300 flex flex-col justify-center items-center text-blue-400 hover:text-blue-500">
                                <img className="w-8 sm:w-10"
                                    src={twitterIcon} alt="twitter" />
                                <p className="font-Inter text-xs font-bold text-gray-500">Twitter</p>
                            </div>
                            <div
                                onClick={() => openInNewTab('https://t.me/zamiata')}
                                className="duration-300 items-center flex flex-col justify-center   text-blue-700 hover:text-blue-800">
                                <img className=" w-8 sm:w-10"
                                    src={telegramIcon} alt="telegram" />
                                <p className="font-Inter text-xs font-bold text-gray-500">Telegram</p>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    )

}
export default CommingSoonPopup;