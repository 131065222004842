import store from "../../store";

const fetchHumanZlBoxDataRequest = () => {
    return {
        type: "CHECK_HUMAN_ZLBOX_REQUEST",
    };
};

const fetchHumanZlBoxDataSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_ZLBOX_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchHumanZlBoxDataFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_ZLBOX_FAILED",
        payload: payload,
    };
};

export const fetchHumanZlBoxData = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanZlBoxDataRequest());
        try {

            let zlBoxBalance = 0
            let ownerZlBox = [{
                id: '',
                address: '',
                data: ''
            }]

            const zlBoxAddress = await store
                .getState()
                .blockchain.zlBoxContract._address

            const zlBox = await store
                .getState()
                .blockchain.zlBoxContract.methods.getTokenBalance(account)
                .call();
            //console.log("Zam BOXs", zlBoxBalance)

            if (zlBox.length > 0) {
                zlBoxBalance = zlBoxBalance + zlBox.length
                for (var i = 0; i < zlBox.length; i++) {
                    const boxDataBase64 = await store
                        .getState()
                        .blockchain.zlBoxContract.methods.tokenURI(zlBox[i])
                        .call();
                    const json = atob(boxDataBase64.substring(29));
                    const result = JSON.parse(json);
                    ownerZlBox.push({
                        id: zlBox[i],
                        address: zlBoxAddress,
                        data: result
                    })
                }
            }

            const zsbBoxAddress = await store
                .getState()
                .blockchain.zbsBoxContrat._address
            const zbsBox = await store
                .getState()
                .blockchain.zbsBoxContrat.methods.getTokenBalance(account)
                .call();
            if (zbsBox.length > 0) {
                zlBoxBalance = zlBoxBalance + zbsBox.length
                for (var i = 0; i < zbsBox.length; i++) {
                    const boxDataBase64 = await store
                        .getState()
                        .blockchain.zbsBoxContrat.methods.tokenURI(zbsBox[i])
                        .call();
                    const json = atob(boxDataBase64.substring(29));
                    const result = JSON.parse(json);
                    ownerZlBox.push({
                        id: zbsBox[i],
                        address: zsbBoxAddress,
                        data: result
                    })
                }
            }
            //console.log(ownerZlBox)
            dispatch(
                fetchHumanZlBoxDataSuccess({
                    zlBoxBalance,
                    ownerZlBox,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanZlBoxDataFailed("Could not load data from contract."));
        }
    };
};