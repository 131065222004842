import React, { useState } from "react";
import { useSelector } from "react-redux";
import OpenBoxPopup from "../Popups/OpenBoxPopup";
import LowBalancePopups from "../Popups/LowBalancePopups";
import Tost from "../Tost";
import openSeaLightIcon from "../../Assets/Icons/OpenSea-Full-Logo (light).png"

const UserZlBox = () => {
    const zlBoxData = useSelector((state) => state.humanZlBox);
    //console.log(zlBoxData)
    const blockchain = useSelector((state) => state.blockchain);
    const walletBal = useSelector((state) => state.walletBalance);
    const [lowBalancePopup, setLoawBalancePopup] = useState(false)
    const [receipt, setReceipt] = useState(null)
    const [boxPopup, setBoxPopup] = useState(false)
    const [boxid, setBoxid] = useState('')
    const [boxName, setBoxName] = useState('')
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    async function openBox(contractAddress, boxId) {
        const gasPrice = await blockchain.web3.eth.getGasPrice()
        let contract
        if (contractAddress === blockchain.zlBoxContract._address) {
            contract = blockchain.zlBoxContract
            setBoxName("zlBox")
        } else if (contractAddress === blockchain.zbsBoxContrat._address) {
            contract = blockchain.zbsBoxContrat
            setBoxName("zsbBox")
        }
        if (walletBal.walletBalance >= gasPrice) {
            setReceipt(null)
            setBoxPopup(true)
            try {
                const tx = await contract.methods.openBox(boxId)
                    .send({
                        gasPrice: gasPrice,
                        from: blockchain.account
                    })
                Tost("success", "Transaction successful")
                setReceipt(tx.events.boxOpen)
                //console.log(tx.events.boxOpen)
                //dispatch(fetchHumanZlBoxData(blockchain.account));
                //dispatch(fetchHumanLandData(blockchain.account));
            } catch (error) {
                setBoxPopup(false)
                if (error.code === 4001) {
                    Tost("error", "Denied transaction signature.")
                } else {
                    Tost("error", error.message)
                }
                //setBoxPopup(false)
                console.log("error", error)
            }
        } else {
            setLoawBalancePopup(true)
        }

    }

    return (
        <>
            {boxPopup && <OpenBoxPopup bName={boxName} txInfo={receipt} userWalletAddress={blockchain.account} closeBoxPopup={setBoxPopup} bid={boxid} />}
            {lowBalancePopup && <LowBalancePopups closeLowBalancePopUp={setLoawBalancePopup} />}
            <div className=" animate__animated animate__fadeIn grid grid-flow-row-dense grid-cols-1 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4  p-2 ">
                {
                    zlBoxData.ownerZlBox.map((item, key) => {
                        if (item.id !== "") {
                            return (
                                <div className="flex justify-center" key={key}>
                                    <div className=" w-5/6 sm:w-44 md:w-52 lg:w-56 flex flex-col m-2  rounded-xl overflow-hidden shadow-md ">
                                        <div className="relative flex  overflow-hidden justify-center items-end ">
                                            <img className=""
                                                src={item.data.image} alt="zam-box" />
                                            <div className="flex p-1 sm:p-1 w-full h-full flex-col justify-between absolute">
                                                <div>
                                                    <button onClick={() => openInNewTab(`${blockchain.openSeaAssetLink}${item.address}/${item.id}`)}>
                                                        <img className=" w-32 sm:w-24 "
                                                            src={openSeaLightIcon} alt="zam-box" />
                                                    </button>
                                                </div>

                                                <div className="">
                                                    <p className=" font-Inter font-bold text-xl sm:text-base text-center text-gray-300"> {item.data.name}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            onClick={() => {
                                                //setSelectedLandDetails(landDetails)
                                                //setAssetImg(SFLimg);
                                                setBoxid(item.id)
                                                openBox(item.address, item.id)
                                            }}
                                            className=" duration-500 w-full  py-3 sm:py-2 text-lg sm:text-base bg-orange-600 font-Inter font-bold text-white hover:bg-blue-600">
                                            Open now
                                        </button>
                                    </div>
                                </div>
                            )
                        } else {
                            return null
                        }
                    })

                }
            </div>
        </>

    )
}
export default UserZlBox