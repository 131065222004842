// log
import store from "../store";

const fetchPresellZamDataRequest = () => {
    return {
        type: "CHECK_PRESELLZAM_DATA_REQUEST",
    };
};

const fetchPresellZamDataSuccess = (payload) => {
    return {
        type: "CHECK_PRESELLZAM_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchPresellZamDataFailed = (payload) => {
    return {
        type: "CHECK_PRESELLZAM_DATA_FAILED",
        payload: payload,
    };
};

export const fetchPresellZamData = () => {
    return async (dispatch) => {
        dispatch(fetchPresellZamDataRequest());
        try {
            //let presellZam = "123"
            let presellZam = await store
                .getState()
                .blockchain.presllZamTokenContract.methods.getDetails()
                .call();

            //console.log(presellZam);
            dispatch(
                fetchPresellZamDataSuccess({
                    presellZam
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchPresellZamDataFailed("Could not load PresellZam data from contract."));
        }
    };
};