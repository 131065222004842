import Tost from "./Tost";
import Web3 from "web3";
const switchNetwork = async () => {
    //matic textnet - 80001
    //matic mainnet - 137
    //chainName: 'Polygon Mainnet',
    //nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    //rpcUrls: ['https://polygon-rpc.com'],
    //blockExplorerUrls: ['https://www.polygonscan.com'],
    let chainId = '137'; chainId = Web3.utils.toHex(chainId);
    if (window.ethereum) {
        try {
            // Try to switch to the Mumbai testnet
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainId }], // Check networks.js for hexadecimal network ids
            });
        } catch (error) {
            // This error code means that the chain we want has not been added to MetaMask
            // In this case we ask the user to add it to their MetaMask
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: chainId,
                                chainName: 'Polygon Mainnet',
                                rpcUrls: ['https://polygon-rpc.com'],
                                nativeCurrency: {
                                    name: "Matic",
                                    symbol: "MATIC",
                                    decimals: 18
                                },
                                blockExplorerUrls: ["https://www.polygonscan.com"]
                            },
                        ],
                    });
                } catch (error) {
                    Tost("error", error.message)
                }
            }

            if (error.code === 4001) {
                Tost("error", "Request rejected.")
            } else {
                Tost("error", error.message)
            }
        }
    }
}
export default switchNetwork;