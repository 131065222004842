// log
import store from "../store";

const fetchZSBBoxDataRequest = () => {
    return {
        type: "CHECK_ZSBBOX_DATA_REQUEST",
    };
};

const fetchZSBBoxDataSuccess = (payload) => {
    return {
        type: "CHECK_ZSBBOX_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchZSBBoxDataFailed = (payload) => {
    return {
        type: "CHECK_ZSBBOX_DATA_FAILED",
        payload: payload,
    };
};

export const fetchZSBBoxData = () => {
    return async (dispatch) => {
        dispatch(fetchZSBBoxDataRequest());
        try {
            let zsbBox = await store
                .getState()
                .blockchain.zbsBoxContrat.methods.getBoxData()
                .call();
            //console.log(zsbBox)
            dispatch(
                fetchZSBBoxDataSuccess({
                    zsbBox,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchZSBBoxDataFailed("Could not load box data from contract."));
        }
    };
};