import React from "react";
import { AiFillCloseCircle } from 'react-icons/ai'
const DirectFarmlandMintingPopup = ({ closeDirectMintPopup, a }) => {

    return (
        <div className=" bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className='bg-white  max-w-sm rounded-xl shadow-xl p-2 mx-2'>
                <div className=" flex justify-end">
                    <button
                        onClick={() => {
                            closeDirectMintPopup(false)

                        }}
                        className="cursor-pointer text-gray-500 hover:text-gray-700  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>
                <div className=" space-y-5 py-5 flex flex-col justify-center items-center">
                    <p className=" font-Archivo font-extrabold text-2xl text-gray-800">At this moment</p>
                    {a === 'land' &&
                        <p className=" font-Inter  text-gray-600 "><span className="font-bold text-lg text-gray-800">Lands</span> cannot be  minted directly. Only the <span className="font-bold text-lg text-orange-600">Zamiata land box</span> can be used for <span className="font-bold text-lg text-blue-600">minting</span>.</p>

                    }
                    {a === 'barn' &&
                        <p className=" font-Inter  text-gray-600 "><span className="font-bold text-lg text-gray-800">Barn</span> cannot be  minted directly. Only the <span className="font-bold text-lg text-orange-600">Zamiata storage building box</span> can be used for <span className="font-bold text-lg text-blue-600">minting</span>.</p>

                    }
                    {a === 'waterTank' &&
                        <p className=" font-Inter  text-gray-600 "><span className="font-bold text-lg text-gray-800">Water Tank</span> cannot be  minted directly. Only the <span className="font-bold text-lg text-orange-600">Zamiata storage building box</span> can be used for <span className="font-bold text-lg text-blue-600">minting</span>.</p>

                    }
                    {a === 'silo' &&
                        <p className=" font-Inter  text-gray-600 "><span className="font-bold text-lg text-gray-800">Silo</span> cannot be  minted directly. Only the <span className="font-bold text-lg text-orange-600">Zamiata storage building box</span> can be used for <span className="font-bold text-lg text-blue-600">minting</span>.</p>

                    }

                </div>
            </div>
        </div>
    )
}
export default DirectFarmlandMintingPopup;