const initialState = {
    loading: true,
    presellZam: null,
    error: false,
    errorMsg: "",
};

const presellZamDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_PRESELLZAM_DATA_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_PRESELLZAM_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                presellZam: action.payload.presellZam,

            };
        case "CHECK_PRESELLZAM_DATA_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default presellZamDataReducer;