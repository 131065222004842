import store from "../../store";

const fetchHumanLandDataRequest = () => {
    return {
        type: "CHECK_HUMAN_LAND_REQUEST",
    };
};

const fetchHumanLandDataSuccess = (payload) => {
    return {
        type: "CHECK_HUMAN_LAND_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchHumanLandDataFailed = (payload) => {
    return {
        type: "CHECK_HUMAN_LAND_FAILED",
        payload: payload,
    };
};

export const fetchHumanLandData = (account) => {
    return async (dispatch) => {
        dispatch(fetchHumanLandDataRequest());
        try {

            let landBalance = 0
            let ownerLands = [{
                id: "",
                address: '',
                land: ""
            }]
            const landAddress = await store
                .getState()
                .blockchain.lands._address
            const lands = await store
                .getState()
                .blockchain.lands.methods.getOwnerBalance(account)
                .call();
            //console.log(landBalance)
            if (lands.length > 0) {
                landBalance = landBalance + lands.length
                for (var i = 0; i < lands.length; i++) {
                    const landDataBase64 = await store
                        .getState()
                        .blockchain.lands.methods.tokenURI(lands[i])
                        .call();
                    const json = atob(landDataBase64.substring(29));
                    const result = JSON.parse(json);

                    //console.log(result);
                    ownerLands.push({
                        id: lands[i],
                        address: landAddress,
                        land: result
                    })
                }
            }
            //console.log(lands)

            dispatch(
                fetchHumanLandDataSuccess({
                    landBalance,
                    ownerLands,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchHumanLandDataFailed("Could not load data from contract."));
        }
    };
};