const initialState = {
    loading: false,
    account: null,
    openSeaAssetLink: null,
    lands: null,
    farmlandData: null,
    zlBoxContract: null,
    zbsBoxContrat: null,
    barnContract: null,
    barnDataContract: null,
    waterTankContract: null,
    waterTankDataContract: null,
    siloContract: null,
    siloDataContract: null,
    userAccount: null,
    zamTokenContract: null,
    presllZamTokenContract: null,
    networkid: null,
    isAvatarMint: false,
    humanDna: null,
    web3: null,
    errorMsg: "",
    errorStatus: false,
};

const blockchainReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CONNECTION_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CONNECTION_SUCCESS":
            return {
                ...state,
                loading: false,
                errorStatus: false,
                account: action.payload.account,
                openSeaAssetLink: action.payload.openSeaAssetLink,
                userAccount: action.payload.userAccount,
                farmlandData: action.payload.farmlandData,
                lands: action.payload.lands,
                zlBoxContract: action.payload.zlBoxContract,
                zbsBoxContrat: action.payload.zbsBoxContrat,
                barnContract: action.payload.barnContract,
                barnDataContract: action.payload.barnDataContract,
                waterTankContract: action.payload.waterTankContract,
                waterTankDataContract: action.payload.waterTankDataContract,
                siloContract: action.payload.siloContract,
                siloDataContract: action.payload.siloDataContract,
                zamTokenContract: action.payload.zamTokenContract,
                presllZamTokenContract: action.payload.presllZamTokenContract,
                web3: action.payload.web3,
                networkid: action.payload.networkid,
                isAvatarMint: action.payload.isAvatarMint,
                humanDna: action.payload.humanDna,
            };
        case "CONNECTION_FAILED":
            return {
                ...initialState,
                loading: false,
                errorStatus: true,
                errorMsg: action.payload,
            };
        case "UPDATE_ACCOUNT":
            return {
                ...state,
                account: action.payload.account,
            };
        default:
            return state;
    }
}
export default blockchainReducer;