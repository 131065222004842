const initialState = {
    loading: true,
    walletBalance: null,
    error: false,
    errorMsg: "",
};

const humanBalanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_HUMAN_WALLET_BALANCE_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_HUMAN_WALLET_BALANCE_SUCCESS":
            return {
                ...initialState,
                loading: false,
                walletBalance: action.payload.walletBalance,
            };
        case "CHECK_HUMAN_WALLET_BALANCE_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default humanBalanceReducer;