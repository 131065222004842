import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainAction";
import { fetchLandData } from "./redux/data/landDataActions";
import { fetchZlBoxData } from "./redux/data/zlBoxDataActions"
import { fetchHumanData } from "./redux/data/humanDataActions"
import { fetchHumanLandData } from "./redux/data/humanData/humanLandDataActions"
import { fetchHumanZlBoxData } from "./redux/data/humanData/humanZlBoxDataActions"
import { fetchStorageData } from "./redux/data/storageBuildingsDataActions"
import { fetchHumanStorageBuildingData } from "./redux/data/humanData/humanStorageBuildingDataActions"
import { fetchZSBBoxData } from "./redux/data/zsbBoxDataActions"
import { fetchPresellZamData } from "./redux/data/presellZamDataActions"
import { fetchHumanZamToken } from "./redux/data/humanData/humanZamTokenActions"
import { ToastContainer } from 'react-toastify';
import Home from "./Pages/Home";
import Account from "./Pages/user/Account";
import Marketplace from "./Pages/Marketplace";
import Tokens from "./Pages/Tokens";

function App() {
  const blockchain = useSelector((state) => state.blockchain);
  const dispatch = useDispatch();
  useEffect(() => {
    if (blockchain.account !== null) {
      dispatch(fetchLandData());
      dispatch(fetchZlBoxData());
      dispatch(fetchStorageData());
      dispatch(fetchZSBBoxData());
      dispatch(fetchPresellZamData());
      dispatch(fetchHumanData(blockchain.account));
      dispatch(fetchHumanLandData(blockchain.account));
      dispatch(fetchHumanZlBoxData(blockchain.account));
      dispatch(fetchHumanStorageBuildingData(blockchain.account));
      dispatch(fetchHumanZamToken(blockchain.account));

    }

  }, [blockchain.account]);

  useEffect(() => {
    dispatch(connect());


  }, [dispatch]);

  return (
    <>

      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Marketplace" element={<Marketplace />} />
          <Route path="user/Account" element={<Account />} />
          <Route path="Tokens" element={<Tokens />} />
        </Routes>
      </BrowserRouter>
    </>


  );
}

export default App;
