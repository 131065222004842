import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MetamaskPopup from "../components/Popups/MetamaskPopup"
import Loading from "../components/loadingComp/Loading";
import GetLands from "../components/Marketplace/GetLands";
import GetZlBox from "../components/Marketplace/GetZlBox";
import GetZSBBox from "../components/Marketplace/GetZSBBox";
import { useSelector } from "react-redux";


const Marketplace = () => {
    const blockchain = useSelector((state) => state.blockchain);
    const landData = useSelector((state) => state.landData);
    //console.log(landData)
    const [metamaskPopup, setMetamaskPopup] = useState(false)
    useEffect(() => {
        if (blockchain.errorStatus) {
            setMetamaskPopup(true)
        }
    }, [blockchain.errorStatus]);
    return (
        <>

            {metamaskPopup && <MetamaskPopup closeMetamaskPopup={setMetamaskPopup} />}

            <div className="flex flex-col h-screen " >
                <Header />
                <main className="flex-1 overflow-scroll">
                    <div className=" py-4  bg-bg1">
                        <p className=" text-center font-Archivo font-extrabold px-2 sm:px-0 text-3xl  lg:text-4xl text-gray-800">Welcome to the <span className=" text-orange-600">Zamiata <br /> Marketplace</span> </p>
                        <div className="mx-5  md:mx-14 lg:mx-24  pt-8  items-center space-y-4 md:space-y-0 md:space-x-4 grid grid-flow-row-dense md:grid-cols-2 ">
                            <div className=" flex">
                                <GetZlBox />
                            </div>
                            <div className="flex">
                                <GetZSBBox />
                            </div>


                        </div>
                        {/* <div className="  pt-4  items-center ">
                            <GetZSBBox />
                        </div> */}
                        <div className=" flex justify-center items-center px-5 md:px-14 lg:px-24 py-4 ">
                            {
                                landData.loading ?
                                    <Loading />
                                    :
                                    (<GetLands />)
                            }
                        </div>
                    </div>


                </main>
            </div>
        </>
    )
}
export default Marketplace;