// log
import store from "../store";

const fetchZlBoxDataRequest = () => {
    return {
        type: "CHECK_ZLBOX_DATA_REQUEST",
    };
};

const fetchZlBoxDataSuccess = (payload) => {
    return {
        type: "CHECK_ZLBOX_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchZlBoxDataFailed = (payload) => {
    return {
        type: "CHECK_ZLBOX_DATA_FAILED",
        payload: payload,
    };
};

export const fetchZlBoxData = () => {
    return async (dispatch) => {
        dispatch(fetchZlBoxDataRequest());
        try {
            let zlBox = await store
                .getState()
                .blockchain.zlBoxContract.methods.getBoxData()
                .call();
            dispatch(
                fetchZlBoxDataSuccess({
                    zlBox,
                    //allOwnerLips,
                })
            );
        } catch (err) {
            console.log(err);
            dispatch(fetchZlBoxDataFailed("Could not load box data from contract."));
        }
    };
};