import React from "react";
import { AiFillCloseCircle } from 'react-icons/ai'
import { useSelector } from "react-redux";


const BuildingDetailsPopup = ({ closeDetailsPopop, bd, a }) => {
    const blockchain = useSelector((state) => state.blockchain);
    const acreToMeterSquere = 247 // (4047/1000000)
    return (
        <div className="   bg-white bg-opacity-90 z-10 absolute inset-0 flex justify-center items-center">
            <div className={` bg-white   flex flex-col justify-center items-center  overflow-hidden max-w-sm sm:max-w-lg rounded-xl shadow-xl  mx-2`}>
                <div className="flex w-full justify-between bg-slate-800 items-center py-2 px-5 ">
                    <span className=" font-bold font-Archivo text-xl text-orange-600">{a}<span className=" font-medium text-gray-500"></span></span>
                    <button

                        onClick={() => {
                            closeDetailsPopop(false)

                        }}
                        className=" cursor-pointer text-gray-300 hover:text-gray-500  duration-500  flex justify-end items-end">
                        <AiFillCloseCircle size={25} />
                    </button>
                </div>
                <div className="flex  font-Archivo  rounded-xl  justify-center">
                    <div className="flex flex-col relative w-5/6 sm:w-2/3 m-2 rounded-lg  overflow-hidden justify-end items-center">
                        <img className="" src={bd.imageUriPath} alt="building" />
                    </div>
                    {a === 'Water Tank' &&
                        <div className=" font-Archivo text-gray-800 text-sm flex flex-col  justify-between  w-full px-2">
                            <div className=" mt-4 w-full space-y-1 sm:space-y-2 ">
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Area</span>
                                    <span className="font-Inter text-sm text-gray-600">{bd.area / acreToMeterSquere} m{"\u00b2"}</span>
                                </div>
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Storage Capacity</span>
                                    <span className=" font-Inter text-sm text-gray-600">{bd.storageCapacity / 1000} {bd.unitOfMeasurement}</span>
                                </div>

                            </div>
                            <div className="flex flex-col space-y-1">
                                <div className=" items-center text-purple-600 flex justify-center space-x-2">
                                    <span className=" font-bold">Price</span>
                                    <span className="font-Inter  text-sm ">{blockchain.web3.utils.fromWei(bd.price, 'ether')} Matic</span>
                                </div>

                                <div className="">
                                    <button
                                        disabled
                                        // onClick={() => {

                                        //     mintFn(blockchain.barnContract, storageBuildings.barnData._isDirectMintable, storageBuildings.barnStorageData.id, storageBuildings.barnStorageData.price)
                                        // }}
                                        className="w-full   px-3  py-2  rounded-lg font-Inter font-bold text-white bg-gray-300">
                                        Mint now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }
                    {a === 'Barn' &&
                        <div className=" font-Archivo text-gray-800 text-sm flex flex-col  justify-between  w-full px-2">
                            <div className=" mt-4 w-full space-y-1 sm:space-y-2 ">
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Area</span>
                                    <span className="font-Inter text-sm text-gray-600">{bd.area / acreToMeterSquere} m{"\u00b2"}</span>
                                </div>
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Storage Volume</span>
                                    <span className=" font-Inter text-sm text-gray-600">{bd.storageVolume / acreToMeterSquere} m{"\u00b3"}</span>
                                </div>

                            </div>
                            <div className="flex flex-col space-y-1">
                                <div className=" items-center text-purple-600 flex justify-center space-x-2">
                                    <span className=" font-bold">Price</span>
                                    <span className="font-Inter  text-sm ">{blockchain.web3.utils.fromWei(bd.price, 'ether')} Matic</span>
                                </div>

                                <div className="">
                                    <button
                                        disabled
                                        // onClick={() => {

                                        //     mintFn(blockchain.barnContract, storageBuildings.barnData._isDirectMintable, storageBuildings.barnStorageData.id, storageBuildings.barnStorageData.price)
                                        // }}
                                        className="w-full   px-3  py-2  rounded-lg font-Inter font-bold text-white bg-gray-300">
                                        Mint now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }

                    {a === 'Silo' &&
                        <div className=" font-Archivo text-gray-800 text-sm flex flex-col  justify-between  w-full px-2">
                            <div className=" mt-4 w-full space-y-1 sm:space-y-2 ">
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Area</span>
                                    <span className="font-Inter text-sm text-gray-600">{bd.area / acreToMeterSquere} m{"\u00b2"}</span>
                                </div>
                                <div className=" items-center flex justify-between">
                                    <span className=" font-bold">Storage Capacity</span>
                                    <span className=" font-Inter text-sm text-gray-600">{bd.storageCapacity / 1000} {bd.unitOfMeasurement}</span>
                                </div>

                            </div>
                            <div className="flex flex-col space-y-1">
                                <div className=" items-center text-purple-600 flex justify-center space-x-2">
                                    <span className=" font-bold">Price</span>
                                    <span className="font-Inter  text-sm ">{blockchain.web3.utils.fromWei(bd.price, 'ether')} Matic</span>
                                </div>

                                <div className="">
                                    <button
                                        disabled
                                        // onClick={() => {

                                        //     mintFn(blockchain.barnContract, storageBuildings.barnData._isDirectMintable, storageBuildings.barnStorageData.id, storageBuildings.barnStorageData.price)
                                        // }}
                                        className="w-full   px-3  py-2  rounded-lg font-Inter font-bold text-white bg-gray-300">
                                        Mint now
                                    </button>
                                </div>
                            </div>
                        </div>
                    }


                </div>
                <div className="w-full px-2 pb-2 mx-2">
                    <p className=" text-gray-800 font-bold">About this building</p>
                    <p className="font-Inter text-sm text-gray-600">{bd.description}</p>
                </div>
            </div>
        </div>
    )
}
export default BuildingDetailsPopup;