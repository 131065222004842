import React from "react";
import 'animate.css';
import Header from "../components/Header";
import AllTokensTab from "../components/Tokens/AllTokensTab";
import ZamTokenPreSell from "../components/Tokens/ZamTokenPreSell";

const Tokens = () => {
    return (
        <>
            <div className="flex flex-col" >
                <Header />
                <main className="flex-1 bg-gray-100 overflow-scroll ">
                    <div className="p-2 md:p-5 mx-5 md:mx-10 lg:mx-16">
                        <p className=" text-center font-Archivo font-extrabold px-2 sm:px-0 text-3xl  lg:text-4xl text-gray-800">Zamiata <span className=" text-orange-600">Tokens</span> </p>
                        <div className=" mt-2 sm:mt-5  md:mx-8 lg:mx-16 items-center ">
                            <ZamTokenPreSell />
                            <AllTokensTab />

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
export default Tokens;