const initialState = {
    loading: true,
    zsbBox: null,
    error: false,
    errorMsg: "",
};

const zsbBoxDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case "CHECK_ZSBBOX_DATA_REQUEST":
            return {
                ...initialState,
                loading: true,
            };
        case "CHECK_ZSBBOX_DATA_SUCCESS":
            return {
                ...initialState,
                loading: false,
                zsbBox: action.payload.zsbBox,

            };
        case "CHECK_ZSBBOX_DATA_FAILED":
            return {
                ...initialState,
                loading: false,
                error: true,
                errorMsg: action.payload,
            };
        default:
            return state;
    }
};

export default zsbBoxDataReducer;