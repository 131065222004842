import React from "react";
import { Tab } from '@headlessui/react'
import AboutToken from "./AboutToken";


export default function AllTokensTab() {
    // const [tokenName,setTokenName] = useState('')
    // const [tokenSymble, setTokenSymble] = useState('')
    // const [tokenDescription, setTokendescription] = useState('')
    // const [tokenPair, setTokenPair] = useState('')
    return (
        <Tab.Group defaultIndex={0}>
            <Tab.List className=" space-x-3 flex justify-between md:justify-center font-Archivo">
                <Tab className=" rounded-md flex flex-col justify-center items-center ui-selected:shadow-red-500 ui-selected:shadow ui-not-selected:shadow-none   ui-selected:bg-gray-700 ui-selected:text-white  ui-not-selected:bg-white ui-not-selected:text-slate-500 duration-500">
                    <div className=" flex justify-end w-full">
                        <img className=" w-16 sm:w-24"
                            src={require('../../Assets/Tokens/zam-token.png')} alt="zamiata-token" />
                    </div>
                    <p className=" text-sm sm:text-base font-bold">ZAM</p>
                </Tab>

                <Tab className=" rounded-md flex flex-col justify-center items-center ui-selected:shadow-yellow-500 ui-selected:shadow ui-not-selected:shadow-none  ui-selected:bg-gray-700 ui-selected:text-white  ui-not-selected:bg-white ui-not-selected:text-slate-500 duration-500">
                    <div className=" flex justify-end w-full">
                        <img className=" w-16 sm:w-24"
                            src={require('../../Assets/Tokens/zws-token.png')} alt="wheat-seads-token" />
                    </div>
                    <p className=" text-sm sm:text-base font-bold">ZWS</p>
                </Tab>

                <Tab className=" rounded-md flex flex-col justify-center items-center ui-selected:shadow-sky-700 ui-selected:shadow ui-not-selected:shadow-none  ui-selected:bg-gray-700 ui-selected:text-white  ui-not-selected:bg-white ui-not-selected:text-slate-500 duration-500">
                    <div className=" flex justify-end w-full">
                        <img className=" w-16 sm:w-24"
                            src={require('../../Assets/Tokens/zwr-token.png')} alt="water-token" />
                    </div>
                    <p className=" text-sm sm:text-base font-bold">ZWR</p>
                </Tab>
                <Tab className=" rounded-md flex flex-col justify-center items-center ui-selected:shadow-yellow-300 ui-selected:shadow ui-not-selected:shadow-none  ui-selected:bg-gray-700 ui-selected:text-white  ui-not-selected:bg-white ui-not-selected:text-slate-500 duration-500">
                    <div className=" flex justify-end w-full">
                        <img className=" w-16 sm:w-24"
                            src={require('../../Assets/Tokens/zwg-token.png')} alt="wheat-grains-token" />
                    </div>
                    <p className=" text-sm sm:text-base font-bold">ZWG</p>
                </Tab>
                <Tab className=" rounded-md flex flex-col justify-center items-center ui-selected:shadow-amber-100 ui-selected:shadow ui-not-selected:shadow-none ui-selected:bg-gray-700 ui-selected:text-white  ui-not-selected:bg-white ui-not-selected:text-slate-500 duration-500">
                    <div className=" flex justify-end w-full">
                        <img className=" w-16 sm:w-24"
                            src={require('../../Assets/Tokens/zwf-token.png')} alt="wheat-flour-token" />
                    </div>
                    <p className=" text-sm sm:text-base font-bold">ZWF</p>
                </Tab>
            </Tab.List>

            <Tab.Panels className=" mt-3">
                <Tab.Panel>
                    <div>
                        <AboutToken tokenName={'Zamiata'} tokenSymble={'ZAM'} tokenDescription={"is a governance token, and you can use it to pay all in-game fees and buy other in-game tokens."} tokenPair={'USDT'} />
                    </div>
                </Tab.Panel>

                <Tab.Panel>
                    <div>
                        <AboutToken tokenName={'Wheat Seeds'} tokenSymble={'ZWS'} tokenDescription={"Wheat grains can be grown using wheat seeds in farming. Utilize them to make flour from wheat."} tokenPair={'ZAM'} />
                    </div>
                </Tab.Panel>

                <Tab.Panel>
                    <div>
                        <AboutToken tokenName={'Water'} tokenSymble={'ZWR'} tokenDescription={"can be used for irrigationand drinking."} tokenPair={'ZAM'} />
                    </div>
                </Tab.Panel>

                <Tab.Panel>
                    <div>
                        <AboutToken tokenName={'Wheat Grains'} tokenSymble={'ZWG'} tokenDescription={"can be used to make wheat flour, which can then be used to make bread."} tokenPair={'ZAM'} />
                    </div>
                </Tab.Panel>

                <Tab.Panel>
                    <div>
                        <AboutToken tokenName={'Wheat Flour'} tokenSymble={'ZWF'} tokenDescription={"Bread can be made with wheat flour, which is also edible and provides energy."} tokenPair={'ZAM'} />
                    </div>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    )
}